<i18n>
{
    "en": {
        "warning": {
            "beta": "We are still in Beta.",
            "support": "For the moment we only support",
            "features": "Some features might not work with other browsers at the moment."
        }
    },
    "de": {
        "warning": {
            "beta": "Wir sind noch in der Beta.",
            "support": "Für den Moment unterstützen wir nur",
            "features": "Manche Funktionen funktionieren möglicherweise in anderen Browsern noch nicht."
        }
    }
}
</i18n>

<template>
    <v-app>
        <header-bar />
        <error-message />
        <breadcrumbs
            class="breadcrumbs"
            :crumbs="crumbs"
        />

        <div class="content">
            <v-container fluid>
                <router-view />
            </v-container>
        </div>

        <footer-bar />
    </v-app>
</template>


<script>
import Breadcrumbs from '@/components/base/breadcrumbs.component.vue';
import FooterBar from '@/components/app/footer-bar.component.vue';
import HeaderBar from '@/components/app/header-bar.component.vue';
import ErrorMessage from '@/components/app/error-message.component.vue';

import {detect} from 'detect-browser';

export default {
    name: 'App',
    components: {
        Breadcrumbs,
        ErrorMessage,
        FooterBar,
        HeaderBar,
    },
    data: function() {
        return {
            showNonChromeWarning: true,
            externalRoutes: {
                impressum: 'https://www.podwatch.io/impressum',
                privacy: 'https://www.podwatch.io/datenschutz',
                help: 'http://help.podwatch.io',
                about: 'https://www.podwatch.io/impressum',
            },
        };
    },
    computed: {
        crumbs() {
            return this.$store.state.crumbs;
        },
    },
    methods: {
        logout() {
            this.$auth.logout();
        },
        openWindow(target) {
            window.open(target);
        },
        isChrome() {
            const browser = detect();
            return (browser.name === 'chrome') ? true : false;
        },
    },
};
</script>


<style lang="scss" scoped>
.breadcrumbs {
    position: absolute;
    margin-top: 140px;
    margin-left: $content-padding;
}

.content {
    height: 100%;
    margin: 180px $content-padding $content-padding $content-padding;
}
</style>

<style lang="scss">
@import "@/assets/css/base/utilities.scss";
@import "@/assets/css/main.scss";

:root {
    font-size: 62.5%;
}

body {
    font-size: 1.7rem;

    .container-body {
        margin: 0;
        padding: 0;
    }

    .container {
        padding: 0;
        margin: 0;
        max-width: 100%;
    }
}
</style>
