// Store for the profile data of the user. Used by the profile view

import Vue from 'vue';
import {userService} from '@/service/user.service.js';

export default {
    namespaced: true,
    state() {
        return {
            user: undefined,
        };
    },
    mutations: {
        set: (state, data) => {
            Vue.set(state, data.key, data.value);
        },
        setPublisherData: (state, {index, data}) => {
            const publisherWithData = {
                ...state.user.publishers[index],
                data,
            };
            Vue.set(state.user.publishers, index, publisherWithData);
        },
        setPublisherDataById: (state, {id, data}) => {
            const index = state.user.publishers.find((publisher) => publisher.id === id);
            if (index !== -1) {
                state.commit('setPublisherData', {index, data});
            }
        },
    },
    actions: {
        /**
         * Gets publisher / hoster data depending on the roles of the currently logged in user
         * @param {*} context
         */
        fetchUserData: async (context) => {
            if (context.state.user.publishers && context.state.user.publishers.length > 0) {
                for (let i = 0; i < context.state.user.publishers.length; i++) {
                    if (context.state.user.publishers[i].data === undefined) {
                        const publisherData = await userService.fetchPublisherData(context.state.user.publishers[i].id);
                        context.commit('setPublisherData', {index: i, data: publisherData});
                    }
                }
            }
        },

        /**
         * Fetches the roles of the currently logged in user.
         * @param {*} context
         * @return {Object} user object
         */
        fetchUser: async (context) => {
            const user = await userService.fetchUser();
            // This if was added to prevent the header from "jumping" when vising a new page due to the conditional rendering of the Spotify button
            if (context.state.user === undefined) {
                context.commit('set', {key: 'user', value: user});
            }
            return user;
        },

        updatePublisherData: async (context, {id, data}) => {
            const updatedPublisherData = await userService.updatePublisherData(id, data);
            context.commit('setPublisherDataById', {id, data: updatedPublisherData});
        },
    },
    getters: {
        user: (state) => state.user,
    },
};
