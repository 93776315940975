import {createAPIClient} from './service.base';

const client = createAPIClient('/publishers/');

export const podcastsService = {
    fetchPodcasts: async (publisherId, filter = '') => {
        const podcasts = await client.get(`${publisherId}/watched-podcasts/${filter}`);
        return podcasts.data;
    },
    fetchEpisodes: async (publisherId, id, filter = '') => {
        const episodes = await client.get(`${publisherId}/watched-podcasts/${id}/episodes/${filter}`);
        return episodes.data;
    },
    fetchPodcast: async (publisherId, id) => {
        const podcast = await client.get(`${publisherId}/watched-podcasts/${id}`);
        return podcast.data;
    },
    updatePodcast: async (publisherId, id, data) => {
        const updatedPodcast = await client.patch(`${publisherId}/watched-podcasts/${id}`, data);
        return updatedPodcast.data;
    },
    registerPodcast: async (publisherId, data) => {
        const registeredPodcast = await client.post(`${publisherId}/watched-podcasts/`, data);
        return registeredPodcast.data;
    },
    unregisterPodcast: async (publisherId, id) => {
        const unregisteredPodcast = await client.post(`${publisherId}/watched-podcasts/${id}/deactivate_podcast/`);
        return unregisteredPodcast;
    },
    cancelUnregisterPodcast: async (publisherId, id) => {
        const reregisteredPodcast = await client.post(`${publisherId}/watched-podcasts/${id}/cancel_deactivate_podcast/`);
        return reregisteredPodcast;
    },
    withdrawRegistration: async (publisherId, id) => {
        const deletedPodcast = await client.delete(`${publisherId}/watched-podcasts/${id}/`);
        return deletedPodcast;
    },
};
