import {createAPIClient} from './service.base';

const client = createAPIClient('/admin/analytics/analyticswindows/');
const exportClient = createAPIClient('/admin/exports/');


export const agmaDashboardService = {
    fetchOverview: async (monthId) => {
        const overview = await client.get(monthId);
        return overview.data;
    },
    fetchPublisherPodcasts: async (publisherId, monthId) => {
        const publisherPodcasts = await client.get(monthId + '/publishers/' + publisherId);
        return publisherPodcasts.data;
    },
    fetchPublisherMonitor: async (publisherId, monthId) => {
        const publisherMonitor = await client.get(monthId + '/publishers/' + publisherId + '/monitor/');
        return publisherMonitor.data;
    },
    fetchPodcast: async (podcastId, publisherId, monthId) => {
        const podcast = await client.get(monthId + '/watched-podcasts/' + podcastId);
        return podcast.data;
    },
    fetchQueryParams: async (publisherId) => {
        const filter = publisherId ? publisherId : '';
        const queryParams = await client.get(`/query-params/publishers/${filter}`);
        return queryParams.data;
    },

    fetchXLSX: async (filter) => {
        let route;
        if (filter.podcastId) {
            route = `watched_podcasts/${filter.podcastId}/xlsx/`;
        } else if (filter.monthId) {
            route = `analytics_windows/${filter.monthId}/xlsx/`;
        } else if (filter.analyticsWindow) {
            route = `monitoring_data/${filter.analyticsWindow}/xlsx/`;
        } else {
            route = 'all/xlsx/';
        }
        const xlsx = await exportClient.get(route, {responseType: 'blob'});
        return xlsx.data;
    },
};
