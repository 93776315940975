import {createAPIClient} from './service.base';

const client = createAPIClient('admin/news-data/');

export const newsService = {
    fetchNewsArticles: async (filter = '') => {
        const articles = await client.get(`?${filter}`);
        return articles.data;
    },
    addNewsArticle: async (data) => {
        const article = await client.post('', data);
        return article;
    },
    editNewsArticle: async (articleId, data) => {
        const article = await client.put(`${articleId}/`, data);
        return article;
    },
    deleteNewsArticle: async (articleId) => {
        const article = await client.delete(`${articleId}`);
        return article;
    },
};
