// Store for the Spotify data that can be uploaded by a publisher

import Vue from 'vue';
import {spotifyService} from '@/service/spotify.service.js';

export default {
    namespaced: true,
    state() {
        return {
            analyticsWindows: [],
            uploadedData: [],
            spotifyEligiblePodcasts: [],
        };
    },
    mutations: {
        set: (state, data) => {
            Vue.set(state, data.key, data.value);
        },
        setState: (state, data) => {
            Object.assign(state, data);
        },
    },
    actions: {
        fetchAnalyticsWindows: async (context) => {
            const windows = await spotifyService.fetchAnalyticsWindows();
            context.commit('set', {key: 'analyticsWindows', value: windows});
        },
        fetchUploadedData: async (context, {publisherId}) => {
            const data = await spotifyService.fetchUploadedData(publisherId);
            context.commit('set', {key: 'uploadedData', value: data.data});
        },
        fetchSpotifyEligiblePodcasts: async (context, {publisherId, windowId=null}) => {
            const data = await spotifyService.fetchSpotifyEligiblePodcasts(publisherId, windowId);
            context.commit('set', {key: 'spotifyEligiblePodcasts', value: data});
        },
        uploadData: async (context, {publisherId, data}) => {
            await spotifyService.uploadData(publisherId, data);
        },
        deleteData: async (context, {publisherId, id}) => {
            await spotifyService.deteteData(publisherId, id);
        },
    },
    getters: {
        openAnalyticsWindows: (state) => {
            return state.analyticsWindows.filter((w) => w.is_open);
        },
        uploadedData: (state) => {
            return state.uploadedData;
        },
        spotifyEligiblePodcasts: (state) => {
            return state.spotifyEligiblePodcasts;
        },
    },
};
