import Vue from 'vue';
import {newsService} from '@/service/news.service.js';
import {attachmentService} from '@/service/attachment.service.js';

export default {
    namespaced: true,
    state() {
        return {
            articles: [],
        };
    },
    mutations: {
        set: (state, data) => {
            Vue.set(state, data.key, data.value);
        },
        setState: (state, data) => {
            Object.assign(state, data);
        },
    },
    actions: {
        fetchArticles: async (context, {filter}) => {
            const articles = await newsService.fetchNewsArticles(filter);
            context.commit('set', {key: 'articles', value: articles});
        },
        addNewsArticle: async (context, {data}) => {
            const article = await newsService.addNewsArticle(data);
            const articles = context.state.articles;
            articles.data.push(article);
            context.commit('set', {key: 'articles', value: articles});
        },
        editNewsArticle: async (context, {articleId, data}) => {
            const article = await newsService.editNewsArticle(articleId, data);
            const articles = context.state.articles;
            articles.data.push(article);
            context.commit('set', {key: 'articles', value: articles});
        },
        deleteNewsArticle: async (context, {articleId}) => {
            await newsService.deleteNewsArticle(articleId);
        },
        downloadAttachment: async (context, {attachmentId}) => {
            const attachment = await attachmentService.downloadAttachment(attachmentId);
            return attachment.data;
        },
    },
    getters: {
        articles: (state) => state.articles,
    },
};
