import {createAPIClient} from './service.base';

const client = createAPIClient('/admin/analytics-windows/');
const externalClinet = createAPIClient('/admin/external-data/');

export const amgaSpotifyService = {
    fetchSpotifyWindows: async () => {
        const windows = await client.get();
        return windows.data;
    },
    fetchPodcasts: async (window, filter)=>{
        const podcasts = await externalClinet.get(`${window}/files/?${filter}`);
        return podcasts.data;
    },
    approvePodcast: async (window, podcastId)=>{
        const podcast = await externalClinet.post(`${window}/files/${podcastId}/approve/`);
        return podcast;
    },
    rejectPodcast: async (window, podcastId)=>{
        const podcast = await externalClinet.post(`${window}/files/${podcastId}/reject/`);
        return podcast;
    },
    getImage: async (window, podcastId)=>{
        const image = await externalClinet.get(`${window}/files/${podcastId}/proof_file_bin/`, {responseType: 'blob'});
        return image;
    },
    downloadCSV: async (window, podcastId)=>{
        const csv = await externalClinet.get(`${window}/files/${podcastId}/file_bin/`);
        return csv;
    },
    approvePending: async (window)=>{
        const podcast = await externalClinet.post(`${window}/approve-pending/`);
        return podcast;
    },
    rejectPending: async (window)=>{
        const podcast = await externalClinet.post(`${window}/reject-pending/`);
        return podcast;
    },
};
