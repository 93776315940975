import {createAPIClient} from './service.base';

const client = createAPIClient('');

export const logfilesService = {
    fetchLogfiles: async (hosterId, filter = '') => {
        const logfiles = await client.get(`/hosters/${hosterId}/raw-log-files/${filter}`);
        return logfiles.data;
    },
    deleteLogfile: async (hosterId, id) => {
        const deletedLogfile = await client.delete(`/hosters/${hosterId}/raw-log-files/${id}/`);
        return deletedLogfile.data;
    },
};
