// error bar that displays an error of the global store that can be dismissed by clicking the X

<template>
    <transition name="fadeAway">
        <!-- ERROR -->
        <div
            v-if="$store.state.error"
            data-cy="error-message"
            class="message message--error"
        >
            <span>
                <v-icon class="help-icon__close">mdi-alert-circle-outline</v-icon>
                {{ $store.state.error }}
            </span>
            <button
                class="error-message__close-button"
                @click.prevent="dismiss"
            >
                <v-icon class="help-icon__close">
                    mdi-close
                </v-icon>
            </button>
        </div>

        <!-- SUCCESS -->
        <div
            v-if="$store.state.success"
            data-cy="information-message"
            class="message message--success"
        >
            {{ $store.state.success }}
            <button
                class="message__close-button"
                @click.prevent="dismiss"
            >
                <v-icon class="help-icon__close">
                    mdi-close
                </v-icon>
            </button>
        </div>
    </transition>
</template>

<script>
export default {
    name: 'ErrorMessage',
    methods: {
        /**
         * remove the error message
         */
        dismiss() {
            this.$store.commit('clearInfoMessages');
        },
    },
};
</script>

<style lang="scss" scoped>
.message {
    position: fixed;
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 130px;
    padding-left: $content-padding;
    padding-right: $content-padding;
    z-index: 1000;

    font-size: 1.7rem;
    line-height: 4.5rem;

    &__close-button {
        display: flex;
        align-items: center;
    }

    &--error {
        background-color: $color-error-background;
        color: $color-error-text;
    }

    &--success {
        background-color: $color-success-background;
        color: $color-success-text;
    }
}

.fadeAway {
    &-enter-active {
        transition: all 0.3s ease;
    }

    &-leave-active {
        transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
    }

    &-leave-to,
    &-enter {
        opacity: 0;
        transform: translateY(-5rem);
    }
}
</style>
