import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';

// import JSON style variables which include the theme
import light from '../theme';

Vue.use(Vuetify, {
    options: {
        customProperties: true,
    },
});

export default new Vuetify({
    icons: {
        iconfont: 'md',
    },
    theme: {
        themes: {
            light: light,
        },
        options: {
            customProperties: true,
        },
    },
});
