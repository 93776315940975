import Vue from 'vue';
import {amgaSpotifyService} from '@/service/agma-spotify.service';

export default {
    namespaced: true,
    state() {
        return {
            podcasts: [],
            windows: [],
            proofImage: null,
        };
    },
    mutations: {
        set: (state, data) => {
            Vue.set(state, data.key, data.value);
        },
    },
    actions: {
        fetchWindows: async (context) => {
            const windows = await amgaSpotifyService.fetchSpotifyWindows();
            context.commit('set', {key: 'windows', value: windows});
        },
        fetchPodcasts: async (context, {window, filter}) =>{
            if (window) {
                const podcasts = await amgaSpotifyService.fetchPodcasts(window, filter);
                context.commit('set', {key: 'podcasts', value: podcasts});
            }
        },
        approvePodcast: async (context, {window, podcastId}) =>{
            if (window) {
                const podcast = await amgaSpotifyService.approvePodcast(window, podcastId);
                const podcasts = context.state.podcasts;
                const podcastIndex = podcasts.data.findIndex((x) => {
                    return x.id === podcastId;
                });
                podcasts.data[podcastIndex].approval_status = podcast.data.approval_status;
                context.commit('set', {key: 'podcasts', value: podcasts});
            }
        },
        rejectPodcast: async (context, {window, podcastId}) =>{
            if (window) {
                const podcast = await amgaSpotifyService.rejectPodcast(window, podcastId);
                const podcasts = context.state.podcasts;
                const podcastIndex = podcasts.data.findIndex((x) => {
                    return x.id === podcastId;
                });
                podcasts.data[podcastIndex].approval_status = await podcast.data.approval_status;
                context.commit('set', {key: 'podcasts', value: podcasts});
            }
        },
        getImage: async (context, {window, podcastId}) =>{
            const image = await amgaSpotifyService.getImage(window, podcastId);
            return image.data;
        },
        downloadCSV: async (context, {window, podcastId}) =>{
            const csv = await amgaSpotifyService.downloadCSV(window, podcastId);
            return csv.data;
        },
        approvePending: async (context, window) =>{
            const response = await amgaSpotifyService.approvePending(window);
            return response.status;
        },
        rejectPending: async (context, window) =>{
            const response = await amgaSpotifyService.rejectPending(window);
            return response.status;
        },

    },
    getters: {
        windows: (state) => state.windows,
        podcasts: (state) => state.podcasts,
        proofImage: (state) => state.proofImage,
    },
};
