// A normal base-button component that supplies prestyled buttons

<template>
    <button
        class="base-button"
        :class="`base-button--${type}`"
        :disabled="disabled"
        :style="styleVariables"
        v-on="$listeners"
    >
        <slot />
    </button>
</template>

<script>
export default {
    name: 'BaseButton',
    props: {
        backgroundColor: {type: String, required: false, default: 'blue'},
        textColor: {type: String, required: false, default: 'white'},
        disabledBackgroundColor: {type: String, required: false, default: 'lightgrey'},
        disabledTextColor: {type: String, required: false, default: '#929191'},
        type: {type: String, required: false, default: ''},
        disabled: {type: Boolean, required: false, default: false},
    },
    computed: {
        styleVariables() {
            return {
                '--background-color': this.$props.backgroundColor,
                '--text-color': this.$props.textColor,
                '--disabled-background-color': this.$props.disabledBackgroundColor,
                '--disabled-text-color': this.$props.disabledTextColor,
            };
        },
    },
};
</script>

<style lang="scss" scoped>
.base-button {
    padding: 1rem 2rem;
    border: none;
    border-radius: 3px;
    transition: all 0.1s;

    background-color: var(--background-color);
    color: var(--text-color);

    &:not(:active):hover:not([disabled]) {
        box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.2);
    }

    &:hover:not([disabled]) {
        cursor: pointer;
    }

    &:disabled {
        background-color: var(--disabled-background-color);
        color: var(--disabled-text-color);
    }

    &--primary {
        color: white;
        background-color: $primary-color;
    }

    &--secondary {
        color: white;
        background-color: $secondary-color;
    }

    &--transparent {
        color: grey;
        background-color: transparent;

        &:hover {
            background-color: rgba(0, 0, 0, 0.08);
        }
    }

    &--big {
        padding: 3rem 15rem;
        text-transform: uppercase;
    }
}
</style>
