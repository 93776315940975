
import {createAPIClient} from './service.base';

const client = createAPIClient('/analytics/publishers/');
const marketerClient = createAPIClient('/marketers/');
export const personalAnalyticsService = {
    fetchPodcasts: async (publisherId, filter = '') => {
        const podcasts = await client.get(`${publisherId}/watched-podcasts/${filter}`);
        return podcasts.data;
    },
    fetchEpisodes: async (publisherId, filter = '') => {
        const episodes = await client.get(`${publisherId}/episodes/${filter}`);
        return episodes.data;
    },
    fetchPodcastTimeSeries: async (publisherId, filter) => {
        const podcastTimeSeries = await client.get(`${publisherId}/watched-podcasts/${filter}`);
        return podcastTimeSeries.data;
    },
    fetchEpisodeTimeSeries: async (publisherId, filter) => {
        const episodeTimeSeries = await client.get(`${publisherId}/episodes/${filter}`);
        return episodeTimeSeries.data;
    },
    fetchPublisherTotals: async (publisherId, filter) => {
        const publisherTotals = await client.get(`${publisherId}/total${filter}`);
        return publisherTotals.data;
    },
    fetchPublisherLatestTotals: async (publisherId) => {
        const publisherLatestTotals = await client.get(`${publisherId}/latest_analytics_window_total`);
        return publisherLatestTotals.data;
    },
    fetchPodcastTotals: async (publisherId, id, filter) => {
        const podcastTotals = await client.get(`${publisherId}/watched-podcasts/${id}/total${filter}`);
        return podcastTotals.data;
    },
    fetchEpisodeTotals: async (publisherId, id, filter) => {
        const episodeTotals = await client.get(`${publisherId}/episodes/${id}/total${filter}`);
        return episodeTotals.data;
    },
    fetchQueryParams: async (publisherId) => {
        const queryParams = await client.get(`${publisherId}/query-params`);
        return queryParams.data;
    },
    fetchCSV: async (publisherId, filter) => {
        const csv = await client.get(`${publisherId}/${filter}`);
        return csv.data;
    },
    fetchXLSX: async (publisherId, filter) => {
        const xlsx = await client.get(`${publisherId}/${filter}`, {responseType: 'blob'});
        return xlsx.data;
    },
    // marketer
    marketerFetchPodcasts: async (marketerId, publisherId, filter = '') => {
        const podcasts = await marketerClient.get(`${marketerId}/analytics/publishers/${publisherId}/watched-podcasts/${filter}`);
        return podcasts.data;
    },
    marketerFetchEpisodes: async (marketerId, publisherId, filter = '') => {
        const episodes = await marketerClient.get(`${marketerId}/analytics/publishers/${publisherId}/episodes/${filter}`);
        return episodes.data;
    },
    marketerFetchPodcastTimeSeries: async (marketerId, publisherId, filter) => {
        const podcastTimeSeries = await marketerClient.get(`${marketerId}/analytics/publishers/${publisherId}/watched-podcasts/${filter}`);
        return podcastTimeSeries.data;
    },
    marketerFetchEpisodeTimeSeries: async (marketerId, publisherId, filter) => {
        const episodeTimeSeries = await marketerClient.get(`${marketerId}/analytics/publishers/${publisherId}/episodes/${filter}`);
        return episodeTimeSeries.data;
    },
    marketerFetchPublisherTotals: async (marketerId, publisherId, filter) => {
        const publisherTotals = await marketerClient.get(`${marketerId}/analytics/publishers/${publisherId}/total${filter}`);
        return publisherTotals.data;
    },
    marketerFetchPublisherLatestTotals: async (marketerId, publisherId) => {
        const publisherLatestTotals = await marketerClient.get(`${marketerId}/analytics/publishers/${publisherId}/latest_analytics_window_total`);
        return publisherLatestTotals.data;
    },
    marketerFetchPodcastTotals: async (marketerId, publisherId, id, filter) => {
        const podcastTotals = await marketerClient.get(`${marketerId}/analytics/publishers/${publisherId}/watched-podcasts/${id}/total${filter}`);
        return podcastTotals.data;
    },
    marketerFetchEpisodeTotals: async (marketerId, publisherId, id, filter) => {
        const episodeTotals = await marketerClient.get(`${marketerId}/analytics/publishers/${publisherId}/episodes/${id}/total${filter}`);
        return episodeTotals.data;
    },
    marketerFetchQueryParams: async (marketerId, publisherId) => {
        const queryParams = await marketerClient.get(`${marketerId}/analytics/publishers/${publisherId}/query-params`);
        return queryParams.data;
    },
    marketerFetchCSV: async (marketerId, publisherId, filter) => {
        const csv = await marketerClient.get(`${marketerId}/analytics/publishers/${publisherId}/${filter}`);
        return csv.data;
    },
    marketerFetchXLSX: async (marketerId, publisherId, filter) => {
        const xlsx = await marketerClient.get(`${marketerId}/analytics/publishers/${publisherId}/${filter}`, {responseType: 'blob'});
        return xlsx.data;
    },
};
