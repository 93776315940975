
import {createAPIClient} from './service.base';

const client = createAPIClient('admin/analytics/publishers/');
const listClient = createAPIClient('admin/publisher-analytics/');

export const agmaAnalyticsService = {
    fetchPublishers: async () => {
        const publishers = await listClient.get();
        return publishers.data;
    },
    fetchPodcasts: async (publisherId, filter = '') => {
        const podcasts = await client.get(`${publisherId}/watched-podcasts/${filter}`);
        return podcasts.data;
    },
    fetchEpisodes: async (publisherId, filter = '') => {
        const episodes = await client.get(`${publisherId}/episodes/${filter}`);
        return episodes.data;
    },
    fetchPodcastTimeSeries: async (publisherId, filter) => {
        const podcastTimeSeries = await client.get(`${publisherId}/watched-podcasts/${filter}`);
        return podcastTimeSeries.data;
    },
    fetchEpisodeTimeSeries: async (publisherId, filter) => {
        const episodeTimeSeries = await client.get(`${publisherId}/episodes/${filter}`);
        return episodeTimeSeries.data;
    },
    fetchPublisherTotals: async (publisherId, filter) => {
        const publisherTotals = await client.get(`${publisherId}/total${filter}`);
        return publisherTotals.data;
    },
    fetchPublisherLatestTotals: async (publisherId) => {
        const publisherLatestTotals = await client.get(`${publisherId}/publisher/latest_analytics_window_total`);
        return publisherLatestTotals.data;
    },
    fetchPodcastTotals: async (publisherId, id, filter) => {
        const podcastTotals = await client.get(`${publisherId}/watched-podcasts/${id}/total${filter}`);
        return podcastTotals.data;
    },
    fetchEpisodeTotals: async (publisherId, id, filter) => {
        const episodeTotals = await client.get(`${publisherId}/episodes/${id}/total${filter}`);
        return episodeTotals.data;
    },
    fetchQueryParams: async (publisherId) => {
        const queryParams = await client.get(`${publisherId}/query-params`);
        return queryParams.data;
    },
    fetchCSV: async (publisherId, filter) => {
        const csv = await client.get(`${publisherId}/${filter}`);
        return csv.data;
    },
};
