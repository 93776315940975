import {createAPIClient} from './service.base';

const client = createAPIClient('');

export const userService = {
    fetchUser: async () => {
        const role = await client.get('/user/');
        return role.data;
    },
    fetchPublisherData: async (id) => {
        const publisherData = await client.get(`/publishers/${id}/`);
        return publisherData.data;
    },
    updatePublisherData: async (id, data) => {
        const publisherData = await client.patch(`/publishers/${id}/`, data);
        return publisherData.data;
    },
    fetchHosterData: async (id) => {
        const hosterData = await client.get('/hosters/' + id);
        return hosterData.data;
    },
    fetchRegisteredPublishers: async (id) => {
        const registeredPublishersData = await client.get('/hosters/' + id + '/publishers/');
        return registeredPublishersData.data;
    },
};

