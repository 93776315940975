import {getInstance} from './auth.wrapper';
import store from '@/store/index.js';

const identify = () => {
    return store.dispatch('user/fetchUser');
};

export const authGuard = (to, from, next) => {
    const authService = getInstance();

    const fn = () => {
        if (authService.isAuthenticated && authService.user.email_verified) {
            identify().then(() => {
                next();
            });
        } else if (authService.isAuthenticated && !authService.user.email_verified && to.name !== 'auth-invitation') {
            return next({name: 'auth-verify-email'});
        } else if (authService.isAuthenticated && !authService.user.email_verified && to.name === 'auth-invitation') {
            return next();
        } else {
            if (to.name === 'auth-invitation') {
                authService.loginWithRedirect({screen_hint: 'signup', appState: {targetUrl: to.fullPath}});
            } else {
                authService.loginWithRedirect({appState: {targetUrl: to.fullPath}});
            }
        }
    };

    if (!authService.loading) {
        return fn();
    }

    authService.$watch('loading', (loading) => {
        if (loading === false) {
            return fn();
        }
    });
};
