// Store for the data of all podcasts as a whole. Used by the podcasts view

import Vue from 'vue';
import {podcastsService} from '@/service/podcasts.service.js';

export default {
    namespaced: true,
    state() {
        return {
            data: [],
            count: null,
            total: null,
            page: null,
            pageCount: null,
        };
    },
    mutations: {
        set: (state, data) => {
            Vue.set(state, data.key, data.value);
        },
        setState: (state, data) => {
            Object.assign(state, data);
        },
    },
    actions: {
        fetchPodcasts: async (context, {publisherId, filter}) => {
            const podcasts = await podcastsService.fetchPodcasts(publisherId, filter);
            context.commit('setState', podcasts);
        },
        registerPodcast: async (context, {publisherId, newPodcast}) => {
            await podcastsService.registerPodcast(publisherId, newPodcast);
        },
    },
    getters: {
        podcasts: (state) => state,
    },
};
