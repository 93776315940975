// Store for the data of a single podcast. Used by the podcast-details view

import Vue from 'vue';
import {marketerService} from '@/service/marketer.service.js';

export default {
    namespaced: true,
    state() {
        return {
            marketers: [],
            publishersList: [],
            podcasts: {
                data: [],
                count: null,
                total: null,
                page: null,
                pageCount: null,
            },
            podcast: {
                id: '',
                title: '',
                name: '',
                genre: '',
                description: '',
                image_url: '',
                episodes: {
                    data: [],
                    count: null,
                    total: null,
                    page: null,
                    pageCount: null,
                },
                episodes_count: 0,
                registeredOn: '',
                created_at: '',
                status: '',
                hoster: {
                    name: '',
                },
            },
        };
    },
    mutations: {
        setMarketers: (state, data) => {
            Vue.set(state, 'marketers', data);
        },
        set: (state, data) => {
            Vue.set(state, data.key, data.value);
        },
    },
    actions: {
        fetchMarketers: async (context) => {
            const marketers = await marketerService.fetchMarketers();
            context.commit('setMarketers', marketers.data);
        },
        fetchPublisherList: async (context, marketerId) => {
            const publishersList = await marketerService.fetchPublisherList(marketerId);
            context.commit('set', {key: 'publishersList', value: publishersList.data});
        },
        fetchPodcasts: async (context, {marketerId, publisherId, filter}) => {
            const podcasts = await marketerService.fetchPodcasts(marketerId, publisherId, filter);
            context.commit('set', {key: 'podcasts', value: podcasts});
        },
        registerPodcast: async (context, {marketerId, publisherId, newPodcast}) => {
            await marketerService.registerPodcast(marketerId, publisherId, newPodcast);
        },
    },
    getters: {
        marketers: (state) => state.marketers,
        publishersList: (state) => state.publishersList,
        podcasts: (state) => state.podcasts,
    },
};
