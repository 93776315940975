// Header bar at the top of the screen. Consists of the brand logo, navigation buttons to the subsections of the page and a bar below that indicates on which view the user currently is
// The navigation buttons differ depending on the role of the user and they change to a burger menu when the screen is too small

<i18n>
{
    "en": {
        "navigationMenu": {
            "podcasts": "My Podcasts",
            "settings": "Settings",
            "public-analysis": "Public analysis",
            "personal-analysis": "My analysis",
            "spotify-csv-upload": "Spotify Upload",
            "change-role": "Change role",
            "logout": "Logout",
            "logfiles": "Logfiles",
            "profile": "Profile",
            "agma-overview": "Overview",
            "agma-publisher": "Publisher",
            "agma-podcasts": "Podcasts",
            "agma-analysis": "Analysis",
            "agma-exports": "Exports",
            "agma-spotify": "Spotify",
            "agma-target-group" : "TargetGroups",
            "news": "News"
        }
    },
    "de": {
        "navigationMenu": {
            "podcasts": "Meine Podcasts",
            "settings": "Einstellungen",
            "public-analysis": "Ausweisung",
            "personal-analysis": "Meine Analyse",
            "spotify-csv-upload": "Spotify Upload",
            "change-role": "Rolle ändern",
            "logout": "Logout",
            "logfiles": "Logfiles",
            "profile": "Profil",
            "agma-overview": "Übersicht",
            "agma-publisher": "Publisher",
            "agma-podcasts": "Podcasts",
            "agma-analysis": "Analyse",
            "agma-exports": "Exporte",
            "agma-spotify": "Spotify",
            "agma-target-group" : "Zielgruppen",
            "news": "Neuigkeiten"
        }
    }
}
</i18n>

<template>
    <div>
        <!-- NAVIGATION BAR -->

        <!-- user is a publisher -->
        <div
            v-roles.publisher
            class="header-bar"
        >
            <div
                data-cy="header-logo"
                class="header-bar__logo"
                @click="goTo('dashboard')"
            >
                <img src="@/assets/branding/logo.png">
            </div>
            <v-spacer />

            <div class="header-bar__buttons">
                <base-button
                    data-cy="header-public-analysis"
                    type="transparent"
                    class="header-bar__button header-bar__button--collapse"
                    :class="{
                        'header-bar__button--selected': currentDomain === 'public-analysis',
                    }"
                    @click="goTo('public-analysis')"
                >
                    <div
                        class="header-bar__image-wrapper header-bar__image-wrapper--collapse"
                    >
                        <img
                            src="@/assets/icons/voice-id-approved.png"
                            alt=""
                        >
                    </div>
                    {{ $t("navigationMenu.public-analysis") }}
                </base-button>
                <base-button
                    data-cy="header-target-group"
                    type="transparent"
                    class="header-bar__button header-bar__button--collapse"
                    :class="{
                        'header-bar__button--selected': currentDomain === 'agma-target-group',
                    }"
                    @click="redirectToWebsite('https://mapodcast-zielgruppen.agma-mmc.de/', true, token= true)"
                >
                    <div
                        class="header-bar__image-wrapper header-bar__image-wrapper--collapse"
                    >
                        <img
                            src="@/assets/icons/zielgruppen.png"
                            alt=""
                        >
                    </div>
                    {{ $t("navigationMenu.agma-target-group") }}
                </base-button>
                <base-button
                    data-cy="header-personal-analysis"
                    type="transparent"
                    class="header-bar__button header-bar__button--collapse"
                    :class="{
                        'header-bar__button--selected':
                            currentDomain === 'personal-analysis' ||
                            currentDomain == 'personal-analysis-overview',
                    }"
                    @click="goTo('personal-analysis-overview')"
                >
                    <div
                        class="header-bar__image-wrapper header-bar__image-wrapper--collapse"
                    >
                        <img
                            src="@/assets/icons/analytics-graph.png"
                            alt=""
                        >
                    </div>
                    {{ $t("navigationMenu.personal-analysis") }}
                </base-button>
                <base-button
                    data-cy="header-podcasts"
                    type="transparent"
                    class="header-bar__button header-bar__button--collapse"
                    :class="{
                        'header-bar__button--selected':
                            currentDomain === 'podcasts' ||
                            currentDomain === 'podcast-details',
                    }"
                    @click="goTo('podcasts')"
                >
                    <div
                        class="header-bar__image-wrapper header-bar__image-wrapper--collapse"
                    >
                        <img
                            src="@/assets/icons/microphone-podcast-woman.png"
                            alt=""
                        >
                    </div>
                    {{ $t("navigationMenu.podcasts") }}
                </base-button>
                <base-button
                    v-if="showSpotifyCSVUploadButton"
                    data-cy="header-spotify-csv-upload"
                    type="transparent"
                    class="header-bar__button header-bar__button--collapse"
                    :class="{
                        'header-bar__button--selected':
                            currentDomain === 'spotify-csv-upload',
                    }"
                    @click="goTo('spotify-csv-upload')"
                >
                    <div
                        class="header-bar__image-wrapper header-bar__image-wrapper--collapse"
                    >
                        <!-- Attribution: Darius Dan. https://icon-icons.com/icon/spotify-social-network-brand-logo/78858 -->
                        <img
                            src="@/assets/icons/spotify.png"
                            alt=""
                        >
                    </div>
                    {{ $t("navigationMenu.spotify-csv-upload") }}
                </base-button>
                <base-button
                    data-cy="news"
                    type="transparent"
                    class="header-bar__button header-bar__button--collapse"
                    :class="{
                        'header-bar__button--selected': currentDomain === 'news',
                    }"
                    @click="goTo('news')"
                >
                    <div
                        class="header-bar__image-wrapper header-bar__image-wrapper--collapse"
                    >
                        <img
                            src="@/assets/icons/newspaper.png"
                            alt=""
                        >
                    </div>
                    {{ $t("navigationMenu.news") }}
                </base-button>

                <p class="header-bar__separator">
                    |
                </p>
                <base-button
                    data-cy="header-dropdown-button"
                    type="transparent"
                    class="header-bar__button header-bar__button--collapse"
                    @click="toggleDropdownMenu"
                >
                    <div class="header-bar__image-wrapper">
                        <v-icon>more_vert</v-icon>
                    </div>
                </base-button>
                <div
                    class="dropdown-menu"
                    :class="{ 'open': showDropdownMenu }"
                >
                    <base-button
                        data-cy="header-settings"
                        type="transparent"
                        class="header-bar__button header-bar__button--collapse"
                        :class="{
                            'header-bar__button--selected':
                                currentDomain === 'publisher-settings',
                        }"
                        @click="closeBurgerMenuAndGoTo('publisher-settings')"
                    >
                        <div
                            class="header-bar__image-wrapper header-bar__image-wrapper--collapse"
                        >
                            <img
                                src="@/assets/icons/cog.png"
                                alt=""
                            >
                        </div>
                        {{ $t("navigationMenu.settings") }}
                    </base-button>
                    <base-button
                        v-if="numberOfRoles > 1"
                        data-cy="header-change-role"
                        type="transparent"
                        class="header-bar__button header-bar__button--collapse"
                        @click="changeRole()"
                    >
                        <div class="header-bar__image-wrapper header-bar__image-wrapper--collapse">
                            <v-icon>perm_identity</v-icon>
                        </div>
                        {{ $t("navigationMenu.change-role") }}
                    </base-button>
                    <base-button
                        data-cy="header-logout"
                        type="transparent"
                        class="header-bar__button header-bar__button--collapse"
                        @click="logout()"
                    >
                        <div
                            class="header-bar__image-wrapper header-bar__image-wrapper--collapse"
                        >
                            <v-icon>logout</v-icon>
                        </div>
                        {{ $t("navigationMenu.logout") }}
                    </base-button>
                </div>
                <base-button
                    data-cy="header-logout"
                    type="transparent"
                    class="header-bar__button header-bar__burger"
                    @click="toggleBurgerMenu"
                >
                    <v-icon>menu</v-icon>
                </base-button>
            </div>
        </div>

        <!-- user is hoster -->
        <div
            v-roles.hoster
            class="header-bar"
        >
            <div
                data-cy="header-logo"
                class="header-bar__logo"
                @click="goTo('dashboard')"
            >
                <img src="@/assets/branding/logo.png">
            </div>
            <v-spacer />

            <div class="header-bar__buttons">
                <base-button
                    data-cy="header-logfiles"
                    type="transparent"
                    class="header-bar__button header-bar__button--collapse"
                    :class="{
                        'header-bar__button--selected': currentDomain === 'logfiles',
                    }"
                    @click="goTo('logfiles')"
                >
                    <div class="header-bar__image-wrapper header-bar__image-wrapper--collapse">
                        <img
                            src="@/assets/icons/logfiles.png"
                            alt=""
                        >
                    </div>
                    {{ $t("navigationMenu.logfiles") }}
                </base-button>
                <base-button
                    data-cy="header-hoster-profile"
                    type="transparent"
                    class="header-bar__button header-bar__button--collapse"
                    :class="{
                        'header-bar__button--selected': currentDomain === 'hoster-profile',
                    }"
                    @click="goTo('hoster-profile')"
                >
                    <div class="header-bar__image-wrapper header-bar__image-wrapper--collapse">
                        <img
                            src="@/assets/icons/cog.png"
                            alt=""
                        >
                    </div>
                    {{ $t("navigationMenu.profile") }}
                </base-button>
                <base-button
                    data-cy="news"
                    type="transparent"
                    class="header-bar__button header-bar__button--collapse"
                    :class="{
                        'header-bar__button--selected': currentDomain === 'news',
                    }"
                    @click="goTo('news')"
                >
                    <div
                        class="header-bar__image-wrapper header-bar__image-wrapper--collapse"
                    >
                        <img
                            src="@/assets/icons/newspaper.png"
                            alt=""
                        >
                    </div>
                    {{ $t("navigationMenu.news") }}
                </base-button>
                <p class="header-bar__separator">
                    |
                </p>
                <base-button
                    v-if="numberOfRoles > 1"
                    data-cy="header-change-role"
                    type="transparent"
                    class="header-bar__button header-bar__button--collapse"
                    @click="changeRole()"
                >
                    <div class="header-bar__image-wrapper header-bar__image-wrapper--collapse">
                        <v-icon>perm_identity</v-icon>
                    </div>
                    {{ $t("navigationMenu.change-role") }}
                </base-button>
                <base-button
                    data-cy="header-logout"
                    type="transparent"
                    class="header-bar__button header-bar__button--collapse"
                    @click="logout()"
                >
                    <div class="header-bar__image-wrapper header-bar__image-wrapper--collapse">
                        <v-icon>logout</v-icon>
                    </div>
                    {{ $t("navigationMenu.logout") }}
                </base-button>

                <base-button
                    data-cy="header-logout"
                    type="transparent"
                    class="header-bar__button header-bar__burger"
                    @click="toggleBurgerMenu"
                >
                    <v-icon>menu</v-icon>
                </base-button>
            </div>
        </div>

        <!-- user is a marketer -->
        <div
            v-roles.marketer
            class="header-bar"
        >
            <div
                data-cy="header-logo"
                class="header-bar__logo"
                @click="goTo('dashboard')"
            >
                <img src="@/assets/branding/logo.png">
            </div>
            <v-spacer />

            <div class="header-bar__buttons">
                <base-button
                    data-cy="header-public-analysis"
                    type="transparent"
                    class="header-bar__button header-bar__button--collapse"
                    :class="{
                        'header-bar__button--selected': currentDomain === 'public-analysis',
                    }"
                    @click="goTo('public-analysis')"
                >
                    <div
                        class="header-bar__image-wrapper header-bar__image-wrapper--collapse"
                    >
                        <img
                            src="@/assets/icons/voice-id-approved.png"
                            alt=""
                        >
                    </div>
                    {{ $t("navigationMenu.public-analysis") }}
                </base-button>
                <base-button
                    data-cy="header-target-group"
                    type="transparent"
                    class="header-bar__button header-bar__button--collapse"
                    :class="{
                        'header-bar__button--selected': currentDomain === 'agma-target-group',
                    }"
                    @click="redirectToWebsite('https://mapodcast-zielgruppen.agma-mmc.de/', true, token= true)"
                >
                    <div
                        class="header-bar__image-wrapper header-bar__image-wrapper--collapse"
                    >
                        <img
                            src="@/assets/icons/zielgruppen.png"
                            alt=""
                        >
                    </div>
                    {{ $t("navigationMenu.agma-target-group") }}
                </base-button>
                <base-button
                    data-cy="header-agma-podcasts"
                    type="transparent"
                    class="header-bar__button header-bar__button--collapse"
                    :class="{
                        'header-bar__button--selected':
                            currentDomain === 'marketer-publisher-selection' ||
                            currentDomain === 'marketer-podcasts' ||
                            currentDomain === 'marketer-publisher-selection-podcasts',
                    }"
                    @click="goTo('marketer-publisher-selection-podcasts')"
                >
                    <div
                        class="header-bar__image-wrapper header-bar__image-wrapper--collapse"
                    >
                        <img
                            src="@/assets/icons/microphone-podcast-woman.png"
                            alt=""
                        >
                    </div>
                    {{ $t("navigationMenu.podcasts") }}
                </base-button>
                <base-button
                    data-cy="header-agma-analysis"
                    type="transparent"
                    class="header-bar__button header-bar__button--collapse"
                    :class="{
                        'header-bar__button--selected':
                            currentDomain === 'marketer-analysis' ||
                            currentDomain === 'marketer-publisher-selection-analysis',
                    }"
                    @click="goTo('marketer-publisher-selection-analysis')"
                >
                    <div class="header-bar__image-wrapper header-bar__image-wrapper--collapse">
                        <img
                            src="@/assets/icons/analytics-graph.png"
                            alt=""
                        >
                    </div>
                    {{ $t("navigationMenu.personal-analysis") }}
                </base-button>

                <base-button
                    data-cy="news"
                    type="transparent"
                    class="header-bar__button header-bar__button--collapse"
                    :class="{
                        'header-bar__button--selected': currentDomain === 'news',
                    }"
                    @click="goTo('news')"
                >
                    <div
                        class="header-bar__image-wrapper header-bar__image-wrapper--collapse"
                    >
                        <img
                            src="@/assets/icons/newspaper.png"
                            alt=""
                        >
                    </div>
                    {{ $t("navigationMenu.news") }}
                </base-button>
                <p class="header-bar__separator">
                    |
                </p>

                <base-button
                    data-cy="header-dropdown-button"
                    type="transparent"
                    class="header-bar__button header-bar__button--collapse"
                    @click="toggleDropdownMenu"
                >
                    <div class="header-bar__image-wrapper">
                        <v-icon>more_vert</v-icon>
                    </div>
                </base-button>
                <div
                    class="dropdown-menu"
                    :class="{ 'open': showDropdownMenu }"
                >
                    <base-button
                        v-if="numberOfRoles > 1"
                        data-cy="header-change-role"
                        type="transparent"
                        class="header-bar__button header-bar__button--collapse"
                        @click="changeRole()"
                    >
                        <div class="header-bar__image-wrapper header-bar__image-wrapper--collapse">
                            <v-icon>perm_identity</v-icon>
                        </div>
                        {{ $t("navigationMenu.change-role") }}
                    </base-button>
                    <base-button
                        data-cy="header-logout"
                        type="transparent"
                        class="header-bar__button header-bar__button--collapse"
                        @click="logout()"
                    >
                        <div class="header-bar__image-wrapper header-bar__image-wrapper--collapse">
                            <v-icon>logout</v-icon>
                        </div>
                        {{ $t("navigationMenu.logout") }}
                    </base-button>
                </div>

                <base-button
                    data-cy="header-logout"
                    type="transparent"
                    class="header-bar__button header-bar__burger"
                    @click="toggleBurgerMenu"
                >
                    <v-icon>menu</v-icon>
                </base-button>
            </div>
        </div>

        <!-- user is an agma user -->
        <div
            v-roles.employee
            class="header-bar"
        >
            <div
                data-cy="header-logo"
                class="header-bar__logo header-bar__logo-employee"
                @click="goTo('dashboard')"
            >
                <img src="@/assets/branding/logo.png">
            </div>
            <v-spacer />
            <div class="header-bar__buttons">
                <base-button
                    data-cy="header-agma-overview"
                    type="transparent"
                    class="header-bar__button header-bar__button--collapse"
                    :class="{
                        'header-bar__button--selected': currentDomain === 'agma-overview',
                    }"
                    @click="goTo('agma-overview')"
                >
                    <div class="header-bar__image-wrapper header-bar__image-wrapper--collapse-employee">
                        <img
                            src="@/assets/icons/logfiles.png"
                            alt=""
                        >
                    </div>
                    {{ $t("navigationMenu.agma-overview") }}
                </base-button>
                <base-button
                    data-cy="header-agma-publisher"
                    type="transparent"
                    class="header-bar__button header-bar__button--collapse"
                    :class="{
                        'header-bar__button--selected': currentDomain === 'agma-publisher',
                    }"
                    @click="goTo('agma-publisher')"
                >
                    <div class="header-bar__image-wrapper header-bar__image-wrapper--collapse-employee">
                        <img
                            src="@/assets/icons/logfiles.png"
                            alt=""
                        >
                    </div>
                    {{ $t("navigationMenu.agma-publisher") }}
                </base-button>
                <base-button
                    data-cy="header-agma-podcasts"
                    type="transparent"
                    class="header-bar__button header-bar__button--collapse"
                    :class="{
                        'header-bar__button--selected': currentDomain === 'agma-podcasts',
                    }"
                    @click="goTo('agma-podcasts')"
                >
                    <div class="header-bar__image-wrapper header-bar__image-wrapper--collapse-employee">
                        <img
                            src="@/assets/icons/logfiles.png"
                            alt=""
                        >
                    </div>
                    {{ $t("navigationMenu.agma-podcasts") }}
                </base-button>
                <base-button
                    data-cy="header-agma-analysis"
                    type="transparent"
                    class="header-bar__button header-bar__button--collapse"
                    :class="{
                        'header-bar__button--selected': currentDomain === 'agma-analysis',
                    }"
                    @click="goTo('agma-analysis-overview')"
                >
                    <div class="header-bar__image-wrapper header-bar__image-wrapper--collapse-employee">
                        <img
                            src="@/assets/icons/analytics-graph.png"
                            alt=""
                        >
                    </div>
                    {{ $t("navigationMenu.agma-analysis") }}
                </base-button>
                <base-button
                    data-cy="header-public-analysis"
                    type="transparent"
                    class="header-bar__button header-bar__button--collapse"
                    :class="{
                        'header-bar__button--selected': currentDomain === 'public-analysis',
                    }"
                    @click="goTo('public-analysis')"
                >
                    <div
                        class="header-bar__image-wrapper header-bar__image-wrapper--collapse-employee"
                    >
                        <img
                            src="@/assets/icons/voice-id-approved.png"
                            alt=""
                        >
                    </div>
                    {{ $t("navigationMenu.public-analysis") }}
                </base-button>
                <base-button
                    data-cy="header-agma-target-group"
                    type="transparent"
                    class="header-bar__button header-bar__button--collapse"
                    :class="{
                        'header-bar__button--selected': currentDomain === 'agma-target-group',
                    }"
                    @click="redirectToWebsite('https://mapodcast-zielgruppen.agma-mmc.de/', true, token= true)"
                >
                    <div
                        class="header-bar__image-wrapper header-bar__image-wrapper--collapse-employee"
                    >
                        <img
                            src="@/assets/icons/zielgruppen.png"
                            alt=""
                        >
                    </div>
                    {{ $t("navigationMenu.agma-target-group") }}
                </base-button>
                <base-button
                    data-cy="header-agma-spotify"
                    type="transparent"
                    class="header-bar__button header-bar__button--collapse"
                    :class="{
                        'header-bar__button--selected': currentDomain === 'agma-spotify',
                    }"
                    @click="goTo('agma-spotify')"
                >
                    <div
                        class="header-bar__image-wrapper header-bar__image-wrapper--collapse-employee"
                    >
                        <img
                            src="@/assets/icons/spotify.png"
                            alt=""
                        >
                    </div>
                    {{ $t("navigationMenu.agma-spotify") }}
                </base-button>
                <base-button
                    data-cy="header-agma-exports"
                    type="transparent"
                    class="header-bar__button header-bar__button--collapse"
                    :class="{
                        'header-bar__button--selected': currentDomain === 'agma-exports',
                    }"
                    @click="goTo('agma-exports')"
                >
                    <div
                        class="header-bar__image-wrapper header-bar__image-wrapper--collapse-employee"
                    >
                        <img
                            src="@/assets/icons/export.png"
                            alt=""
                        >
                    </div>
                    {{ $t("navigationMenu.agma-exports") }}
                </base-button>

                <base-button
                    data-cy="news"
                    type="transparent"
                    class="header-bar__button header-bar__button--collapse"
                    :class="{
                        'header-bar__button--selected': currentDomain === 'agma-news',
                    }"
                    @click="goTo('agma-news')"
                >
                    <div
                        class="header-bar__image-wrapper header-bar__image-wrapper--collapse-employee"
                    >
                        <img
                            src="@/assets/icons/newspaper.png"
                            alt=""
                        >
                    </div>
                    {{ $t("navigationMenu.news") }}
                </base-button>

                <p class="header-bar__separator">
                    |
                </p>

                <base-button
                    data-cy="header-dropdown-button"
                    type="transparent"
                    class="header-bar__button header-bar__button--collapse"
                    @click="toggleDropdownMenu"
                >
                    <div class="header-bar__image-wrapper">
                        <v-icon>more_vert</v-icon>
                    </div>
                </base-button>
                <div
                    class="dropdown-menu"
                    :class="{ 'open': showDropdownMenu }"
                >
                    <base-button
                        data-cy="header-change-role"
                        type="transparent"
                        class="header-bar__button"
                        @click="changeRole()"
                    >
                        <div class="header-bar__image-wrapper">
                            <v-icon>perm_identity</v-icon>
                        </div>
                        {{ $t("navigationMenu.change-role") }}
                    </base-button>
                    <base-button
                        data-cy="header-logout"
                        type="transparent"
                        class="header-bar__button"
                        @click="logout()"
                    >
                        <div class="header-bar__image-wrapper">
                            <v-icon>logout</v-icon>
                        </div>
                        {{ $t("navigationMenu.logout") }}
                    </base-button>
                </div>
                <base-button
                    data-cy="header-logout"
                    type="transparent"
                    class="header-bar__button header-bar__burger"
                    @click="toggleBurgerMenu"
                >
                    <v-icon>menu</v-icon>
                </base-button>
            </div>
        </div>

        <!-- user is a media agency -->
        <div
            v-roles.media_agency
            class="header-bar"
        >
            <div
                data-cy="header-logo"
                class="header-bar__logo"
                @click="goTo('dashboard')"
            >
                <img src="@/assets/branding/logo.png">
            </div>
            <v-spacer />
            <div class="header-bar__buttons">
                <base-button
                    data-cy="header-public-analysis"
                    type="transparent"
                    class="header-bar__button header-bar__button--collapse"
                    :class="{
                        'header-bar__button--selected': currentDomain === 'public-analysis',
                    }"
                    @click="goTo('public-analysis')"
                >
                    <div
                        class="header-bar__image-wrapper header-bar__image-wrapper--collapse"
                    >
                        <img
                            src="@/assets/icons/voice-id-approved.png"
                            alt=""
                        >
                    </div>
                    {{ $t("navigationMenu.public-analysis") }}
                </base-button>
                <base-button
                    data-cy="header-agma-target-group"
                    type="transparent"
                    class="header-bar__button header-bar__button--collapse"
                    :class="{
                        'header-bar__button--selected': currentDomain === 'agma-target-group',
                    }"
                    @click="redirectToWebsite('https://mapodcast-zielgruppen.agma-mmc.de/', true, token= true)"
                >
                    <div
                        class="header-bar__image-wrapper header-bar__image-wrapper--collapse-employee"
                    >
                        <img
                            src="@/assets/icons/zielgruppen.png"
                            alt=""
                        >
                    </div>
                    {{ $t("navigationMenu.agma-target-group") }}
                </base-button>
                <base-button
                    data-cy="news"
                    type="transparent"
                    class="header-bar__button header-bar__button--collapse"
                    :class="{
                        'header-bar__button--selected': currentDomain === 'news',
                    }"
                    @click="goTo('news')"
                >
                    <div
                        class="header-bar__image-wrapper header-bar__image-wrapper--collapse"
                    >
                        <img
                            src="@/assets/icons/newspaper.png"
                            alt=""
                        >
                    </div>
                    {{ $t("navigationMenu.news") }}
                </base-button>
                <p class="header-bar__separator">
                    |
                </p>
                <base-button
                    data-cy="header-dropdown-button"
                    type="transparent"
                    class="header-bar__button header-bar__button--collapse"
                    @click="toggleDropdownMenu"
                >
                    <div class="header-bar__image-wrapper">
                        <v-icon>more_vert</v-icon>
                    </div>
                </base-button>
                <div
                    class="dropdown-menu"
                    :class="{ 'open': showDropdownMenu }"
                >
                    <base-button
                        v-if="numberOfRoles > 1"
                        data-cy="header-change-role"
                        type="transparent"
                        class="header-bar__button header-bar__button--collapse"
                        @click="changeRole()"
                    >
                        <div class="header-bar__image-wrapper header-bar__image-wrapper--collapse">
                            <v-icon>perm_identity</v-icon>
                        </div>
                        {{ $t("navigationMenu.change-role") }}
                    </base-button>
                    <base-button
                        data-cy="header-logout"
                        type="transparent"
                        class="header-bar__button header-bar__button--collapse"
                        @click="logout()"
                    >
                        <div class="header-bar__image-wrapper header-bar__image-wrapper--collapse">
                            <v-icon>logout</v-icon>
                        </div>
                        {{ $t("navigationMenu.logout") }}
                    </base-button>
                </div>


                <base-button
                    data-cy="header-logout"
                    type="transparent"
                    class="header-bar__button header-bar__burger"
                    @click="toggleBurgerMenu"
                >
                    <v-icon>menu</v-icon>
                </base-button>
            </div>
        </div>

        <!-- user is nothing (role-selection) -->
        <div
            v-if="currentDomain === 'role-selection'"
            class="header-bar"
        >
            <div
                data-cy="header-logo"
                class="header-bar__logo"
                @click="goTo('role-selection')"
            >
                <img src="@/assets/branding/logo.png">
            </div>
            <v-spacer />
            <div class="header-bar__buttons">
                <base-button
                    data-cy="header-logout"
                    type="transparent"
                    class="header-bar__button header-bar__button--collapse"
                    @click="logout()"
                >
                    <div class="header-bar__image-wrapper header-bar__image-wrapper--collapse">
                        <v-icon>logout</v-icon>
                    </div>
                    {{ $t("navigationMenu.logout") }}
                </base-button>

                <base-button
                    data-cy="header-logout"
                    type="transparent"
                    class="header-bar__button header-bar__burger"
                    @click="toggleBurgerMenu"
                >
                    <v-icon>menu</v-icon>
                </base-button>
            </div>
        </div>

        <!-- BURGER MENU -->
        <div
            v-if="burgerMenuOpen"
            class="burger-menu"
        >
            <!-- user is a publisher -->
            <div v-roles.publisher>
                <div class="burger-menu__list">
                    <base-button
                        data-cy="burger-menu-public-analysis"
                        type="transparent"
                        class="header-bar__button"
                        @click="closeBurgerMenuAndGoTo('public-analysis')"
                    >
                        <div
                            class="header-bar__image-wrapper"
                        >
                            <img
                                src="@/assets/icons/voice-id-approved.png"
                                alt=""
                            >
                        </div>
                        {{ $t("navigationMenu.public-analysis") }}
                    </base-button>
                    <base-button
                        data-cy="burger-menu-personal-analysis"
                        type="transparent"
                        class="header-bar__button"
                        @click="closeBurgerMenuAndGoTo('personal-analysis')"
                    >
                        <div
                            class="header-bar__image-wrapper"
                        >
                            <img
                                src="@/assets/icons/analytics-graph.png"
                                alt=""
                            >
                        </div>
                        {{ $t("navigationMenu.personal-analysis") }}
                    </base-button>
                    <base-button
                        data-cy="burger-menu-podcasts"
                        type="transparent"
                        class="header-bar__button"
                        @click="closeBurgerMenuAndGoTo('podcasts')"
                    >
                        <div
                            class="header-bar__image-wrapper"
                        >
                            <img
                                src="@/assets/icons/microphone-podcast-woman.png"
                                alt=""
                            >
                        </div>
                        {{ $t("navigationMenu.podcasts") }}
                    </base-button>
                    <base-button
                        v-if="showSpotifyCSVUploadButton"
                        data-cy="burger-menu-spotify-csv-upload"
                        type="transparent"
                        class="header-bar__button"
                        @click="closeBurgerMenuAndGoTo('spotify-csv-upload')"
                    >
                        <div
                            class="header-bar__image-wrapper header-bar__image-wrapper"
                        >
                            <!-- Attribution: Darius Dan. https://icon-icons.com/icon/spotify-social-network-brand-logo/78858 -->
                            <img
                                src="@/assets/icons/spotify.png"
                                alt=""
                            >
                        </div>
                        {{ $t("navigationMenu.spotify-csv-upload") }}
                    </base-button>
                    <base-button
                        data-cy="burger-menu-news"
                        type="transparent"
                        class="header-bar__button"
                        @click="closeBurgerMenuAndGoTo('news')"
                    >
                        <div
                            class="header-bar__image-wrapper"
                        >
                            <img
                                src="@/assets/icons/newspaper.png"
                                alt=""
                            >
                        </div>
                        {{ $t("navigationMenu.news") }}
                    </base-button>
                    <base-button
                        data-cy="burger-menu-settings"
                        type="transparent"
                        class="header-bar__button"
                        @click="closeBurgerMenuAndGoTo('publisher-settings')"
                    >
                        <div
                            class="header-bar__image-wrapper"
                        >
                            <img
                                src="@/assets/icons/cog.png"
                                alt=""
                            >
                        </div>
                        {{ $t("navigationMenu.settings") }}
                    </base-button>
                    <base-button
                        v-if="numberOfRoles > 1"
                        data-cy="burger-menu-change-role"
                        type="transparent"
                        class="header-bar__button"
                        @click="changeRole()"
                    >
                        <div
                            class="header-bar__image-wrapper"
                        >
                            <v-icon>perm_identity</v-icon>
                        </div>
                        {{ $t("navigationMenu.change-role") }}
                    </base-button>
                    <base-button
                        data-cy="burger-menu-logout"
                        type="transparent"
                        class="header-bar__button"
                        @click="logout()"
                    >
                        <div
                            class="header-bar__image-wrapper"
                        >
                            <v-icon>logout</v-icon>
                        </div>
                        {{ $t("navigationMenu.logout") }}
                    </base-button>
                </div>
            </div>

            <!-- user is hoster -->
            <div v-roles.hoster>
                <div class="burger-menu__list">
                    <base-button
                        data-cy="burger-menu-logfiles"
                        type="transparent"
                        class="header-bar__button"
                        @click="closeBurgerMenuAndGoTo('logfiles')"
                    >
                        <div
                            class="header-bar__image-wrapper"
                        >
                            <img
                                src="@/assets/icons/logfiles.png"
                                alt=""
                            >
                        </div>
                        {{ $t("navigationMenu.logfiles") }}
                    </base-button>
                    <base-button
                        data-cy="burger-menu-hoster-profile"
                        type="transparent"
                        class="header-bar__button"
                        @click="closeBurgerMenuAndGoTo('hoster-profile')"
                    >
                        <div
                            class="header-bar__image-wrapper"
                        >
                            <img
                                src="@/assets/icons/cog.png"
                                alt=""
                            >
                        </div>
                        {{ $t("navigationMenu.profile") }}
                    </base-button>
                    <base-button
                        data-cy="burger-menu-news"
                        type="transparent"
                        class="header-bar__button"
                        @click="closeBurgerMenuAndGoTo('news')"
                    >
                        <div
                            class="header-bar__image-wrapper"
                        >
                            <img
                                src="@/assets/icons/newspaper.png"
                                alt=""
                            >
                        </div>
                        {{ $t("navigationMenu.news") }}
                    </base-button>
                    <base-button
                        v-if="numberOfRoles > 1"
                        data-cy="burger-menu-change-role"
                        type="transparent"
                        class="header-bar__button"
                        @click="changeRole()"
                    >
                        <div
                            class="header-bar__image-wrapper"
                        >
                            <v-icon>perm_identity</v-icon>
                        </div>
                        {{ $t("navigationMenu.change-role") }}
                    </base-button>
                    <base-button
                        data-cy="burger-menu-logout"
                        type="transparent"
                        class="header-bar__button"
                        @click="logout()"
                    >
                        <div
                            class="header-bar__image-wrapper"
                        >
                            <v-icon>logout</v-icon>
                        </div>
                        {{ $t("navigationMenu.logout") }}
                    </base-button>
                </div>
            </div>

            <!-- user is a marketer -->
            <div v-roles.marketer>
                <div class="burger-menu__list">
                    <base-button
                        data-cy="burger-menu-public-analysis"
                        type="transparent"
                        class="header-bar__button"
                        @click="closeBurgerMenuAndGoTo('public-analysis')"
                    >
                        <div
                            class="header-bar__image-wrapper"
                        >
                            <img
                                src="@/assets/icons/voice-id-approved.png"
                                alt=""
                            >
                        </div>
                        {{ $t("navigationMenu.public-analysis") }}
                    </base-button>
                    <base-button
                        data-cy="burger-menu-agma-podcasts"
                        type="transparent"
                        class="header-bar__button"
                        @click="closeBurgerMenuAndGoTo('marketer-publisher-selection-podcasts')"
                    >
                        <div
                            class="header-bar__image-wrapper"
                        >
                            <img
                                src="@/assets/icons/microphone-podcast-woman.png"
                                alt=""
                            >
                        </div>
                        {{ $t("navigationMenu.podcasts") }}
                    </base-button>
                    <base-button
                        data-cy="burger-menu-agma-analysis"
                        type="transparent"
                        class="header-bar__button"
                        @click="closeBurgerMenuAndGoTo('marketer-publisher-selection-analysis')"
                    >
                        <div
                            class="header-bar__image-wrapper"
                        >
                            <img
                                src="@/assets/icons/analytics-graph.png"
                                alt=""
                            >
                        </div>
                        {{ $t("navigationMenu.personal-analysis") }}
                    </base-button>
                    <base-button
                        data-cy="burger-menu-news"
                        type="transparent"
                        class="header-bar__button"
                        @click="closeBurgerMenuAndGoTo('news')"
                    >
                        <div
                            class="header-bar__image-wrapper"
                        >
                            <img
                                src="@/assets/icons/newspaper.png"
                                alt=""
                            >
                        </div>
                        {{ $t("navigationMenu.news") }}
                    </base-button>
                    <base-button
                        v-if="numberOfRoles > 1"
                        data-cy="burger-menu-change-role"
                        type="transparent"
                        class="header-bar__button"
                        @click="changeRole()"
                    >
                        <div
                            class="header-bar__image-wrapper"
                        >
                            <v-icon>perm_identity</v-icon>
                        </div>
                        {{ $t("navigationMenu.change-role") }}
                    </base-button>
                    <base-button
                        data-cy="burger-menu-logout"
                        type="transparent"
                        class="header-bar__button"
                        @click="logout()"
                    >
                        <div
                            class="header-bar__image-wrapper"
                        >
                            <v-icon>logout</v-icon>
                        </div>
                        {{ $t("navigationMenu.logout") }}
                    </base-button>
                </div>
            </div>

            <!-- user is an agma user -->
            <div v-roles.employee>
                <div class="burger-menu__list">
                    <base-button
                        data-cy="burger-menu-agma-overview"
                        type="transparent"
                        class="header-bar__button"
                        @click="closeBurgerMenuAndGoTo('agma-overview')"
                    >
                        <div
                            class="header-bar__image-wrapper"
                        >
                            <img
                                src="@/assets/icons/logfiles.png"
                                alt=""
                            >
                        </div>
                        {{ $t("navigationMenu.agma-overview") }}
                    </base-button>
                    <base-button
                        data-cy="burger-menu-agma-publisher"
                        type="transparent"
                        class="header-bar__button"
                        @click="closeBurgerMenuAndGoTo('agma-publisher')"
                    >
                        <div
                            class="header-bar__image-wrapper"
                        >
                            <img
                                src="@/assets/icons/logfiles.png"
                                alt=""
                            >
                        </div>
                        {{ $t("navigationMenu.agma-publisher") }}
                    </base-button>
                    <base-button
                        data-cy="burger-menu-agma-podcasts"
                        type="transparent"
                        class="header-bar__button"
                        @click="closeBurgerMenuAndGoTo('agma-podcasts')"
                    >
                        <div
                            class="header-bar__image-wrapper"
                        >
                            <img
                                src="@/assets/icons/logfiles.png"
                                alt=""
                            >
                        </div>
                        {{ $t("navigationMenu.agma-podcasts") }}
                    </base-button>
                    <base-button
                        data-cy="burger-menu-agma-analysis"
                        type="transparent"
                        class="header-bar__button"
                        @click="closeBurgerMenuAndGoTo('agma-analysis-overview')"
                    >
                        <div
                            class="header-bar__image-wrapper"
                        >
                            <img
                                src="@/assets/icons/analytics-graph.png"
                                alt=""
                            >
                        </div>
                        {{ $t("navigationMenu.agma-analysis") }}
                    </base-button>
                    <base-button
                        data-cy="burger-menu-public-analysis"
                        type="transparent"
                        class="header-bar__button"
                        @click="closeBurgerMenuAndGoTo('public-analysis')"
                    >
                        <div
                            class="header-bar__image-wrapper"
                        >
                            <img
                                src="@/assets/icons/voice-id-approved.png"
                                alt=""
                            >
                        </div>
                        {{ $t("navigationMenu.public-analysis") }}
                    </base-button>
                    <base-button
                        data-cy="burger-menu-agma-target-group"
                        type="transparent"
                        class="header-bar__button"
                        @click="closeBurgerMenuAndRedirect('https://mapodcast-zielgruppen.agma-mmc.de/')"
                    >
                        <div
                            class="header-bar__image-wrapper"
                        >
                            <img
                                src="@/assets/icons/zielgruppen.png"
                                alt=""
                            >
                        </div>
                        {{ $t("navigationMenu.agma-target-group") }}
                    </base-button>
                    <base-button
                        data-cy="burger-menu-agma-spotify"
                        type="transparent"
                        class="header-bar__button"
                        @click="closeBurgerMenuAndGoTo('agma-spotify')"
                    >
                        <div
                            class="header-bar__image-wrapper"
                        >
                            <img
                                src="@/assets/icons/spotify.png"
                                alt=""
                            >
                        </div>
                        {{ $t("navigationMenu.agma-spotify") }}
                    </base-button>
                    <base-button
                        data-cy="burger-menu-agma-exports"
                        type="transparent"
                        class="header-bar__button"
                        @click="closeBurgerMenuAndGoTo('agma-exports')"
                    >
                        <div
                            class="header-bar__image-wrapper"
                        >
                            <img
                                src="@/assets/icons/export.png"
                                alt=""
                            >
                        </div>
                        {{ $t("navigationMenu.agma-exports") }}
                    </base-button>
                    <base-button
                        data-cy="burger-menu-agma-news"
                        type="transparent"
                        class="header-bar__button"
                        @click="closeBurgerMenuAndGoTo('agma-news')"
                    >
                        <div
                            class="header-bar__image-wrapper"
                        >
                            <img
                                src="@/assets/icons/newspaper.png"
                                alt=""
                            >
                        </div>
                        {{ $t("navigationMenu.news") }}
                    </base-button>
                    <base-button
                        v-if="numberOfRoles > 1"
                        data-cy="burger-menu-change-role"
                        type="transparent"
                        class="header-bar__button"
                        @click="changeRole()"
                    >
                        <div
                            class="header-bar__image-wrapper"
                        >
                            <v-icon>perm_identity</v-icon>
                        </div>
                        {{ $t("navigationMenu.change-role") }}
                    </base-button>
                    <base-button
                        data-cy="burger-menu-logout"
                        type="transparent"
                        class="header-bar__button"
                        @click="logout()"
                    >
                        <div
                            class="header-bar__image-wrapper"
                        >
                            <v-icon>logout</v-icon>
                        </div>
                        {{ $t("navigationMenu.logout") }}
                    </base-button>
                </div>
            </div>

            <!-- user is a media agency -->
            <div v-roles.media_agency>
                <div class="burger-menu__list">
                    <base-button
                        data-cy="burger-menu-news"
                        type="transparent"
                        class="header-bar__button"
                        @click="closeBurgerMenuAndGoTo('news')"
                    >
                        <div
                            class="header-bar__image-wrapper"
                        >
                            <img
                                src="@/assets/icons/newspaper.png"
                                alt=""
                            >
                        </div>
                        {{ $t("navigationMenu.news") }}
                    </base-button>
                    <base-button
                        v-if="numberOfRoles > 1"
                        data-cy="burger-menu-change-role"
                        type="transparent"
                        class="header-bar__button"
                        @click="changeRole()"
                    >
                        <div
                            class="header-bar__image-wrapper"
                        >
                            <v-icon>perm_identity</v-icon>
                        </div>
                        {{ $t("navigationMenu.change-role") }}
                    </base-button>
                    <base-button
                        data-cy="burger-menu-logout"
                        type="transparent"
                        class="header-bar__button"
                        @click="logout()"
                    >
                        <div
                            class="header-bar__image-wrapper"
                        >
                            <v-icon>logout</v-icon>
                        </div>
                        {{ $t("navigationMenu.logout") }}
                    </base-button>
                </div>
            </div>

            <!-- user is nothing (role-selection) -->
            <div v-if="currentDomain === 'role-selection'">
                <div class="burger-menu__list">
                    <base-button
                        data-cy="burger-menu-logout"
                        type="transparent"
                        class="header-bar__button"
                        @click="logout()"
                    >
                        <div
                            class="header-bar__image-wrapper"
                        >
                            <v-icon>logout</v-icon>
                        </div>
                        {{ $t("navigationMenu.logout") }}
                    </base-button>
                </div>
            </div>
        </div>

        <!-- TOP BAR -->
        <div class="top-bar">
            <h2
                v-if="currentDomain"
                class="top-bar__current-domain"
            >
                {{ $t(`headings.${currentDomain}`) }}
            </h2>
        </div>
    </div>
</template>

<script>
import GlobalFunctionsMixin from '@/mixins/globalFunctions.mixin.js';
import BaseButton from '@/components/base/button.component.vue';

export default {
    name: 'HeaderBar',
    components: {
        BaseButton,
    },
    mixins: [GlobalFunctionsMixin],
    data: function() {
        return {
            burgerMenuOpen: false,
            externalRoutes: {
                help: 'http://help.sonarbird.io',
            },
            showDropdownMenu: false,
            showDropdownIcon: true,
        };
    },
    computed: {
        currentDomain() {
            return this.$route.name;
        },
        user() {
            return this.$store.getters['user/user'];
        },
        numberOfRoles() {
            return this.user &&
        this.user.employees &&
        this.user.hosters &&
        this.user.marketers &&
        this.user.media_agencies &&
        this.user.publishers ?
                this.user.employees.length +
            this.user.hosters.length +
            this.user.marketers.length +
            this.user.media_agencies.length +
            this.user.publishers.length :
                0;
        },
        showSpotifyCSVUploadButton() {
            return this.user &&
        this.user.publishers &&
        this.user.publishers.length > 0 ?
                this.user.publishers.some((publisher) => {
                    return publisher.data !== undefined &&
              publisher.id === this.$route.params.roleId ?
                        publisher.data.can_upload_spotify_data :
                        false;
                }) :
                false;
        },
    },
    methods: {
        toggleBurgerMenu() {
            this.burgerMenuOpen = !this.burgerMenuOpen;
        },

        logout() {
            this.$auth.logout();
        },
        changeRole() {
            this.burgerMenuOpen = false;
            this.showDropdownMenu = false;
            this.$router.push({name: 'role-selection'});
        },
        closeBurgerMenuAndGoTo(target) {
            this.burgerMenuOpen = false;
            this.showDropdownMenu = false;
            this.goTo(target);
        },
        closeBurgerMenuAndRedirect(target) {
            this.burgerMenuOpen = false;
            this.showDropdownMenu = false;
            this.redirectToWebsite(target, true, true);
        },
        toggleDropdownMenu() {
            this.showDropdownMenu = !this.showDropdownMenu;
        },
    },
};
</script>

<style lang="scss" scoped>
.header-bar {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 70px;
  align-items: center;
  padding: 0;
  padding-right: 2rem;
  background-color: white;
  z-index: 1000;

  &__logo {
    display: flex;
    align-items: center;
    cursor: pointer;
    height: 65px;
    margin-left: $base-margin-big;
    @media only screen and (max-width: 1500px) {
        height: 50px;
    }

    @media only screen and (max-width: 1200px) {
        height: 35px;
    }

    &-employee {
        @media only screen and (max-width: 1700px) {
            height: 50px;
        }

        @media only screen and (max-width: 1300px) {
            height: 35px;
        }
    }

    img {
      height: 60%;
    }
  }

  &__title {
    font-size: 2.5rem;
    align-self: center;
    @media only screen and (max-width: 1500px) {
        font-size: 2rem;
    }

    @media only screen and (max-width: 1200px) {
        font-size: 1.3rem;
    }

    &-employee {
        @media only screen and (max-width: 1700px) {
            font-size: 2rem;
        }

        @media only screen and (max-width: 1300px) {
            font-size: 1.3rem;
        }
    }
  }

  &__buttons {
    display: flex;
    align-items: center;
  }

  &__button {
    display: flex;
    align-items: center;
    color: $color-text-primary;

    &--selected {
      color: $color-text-highlight;
    }

    &--collapse {
      @media only screen and (max-width: 1100px) {
        display: none;
      }
    }
  }

  &__image-wrapper {
    display: flex;
    width: 3rem;
    margin-right: 1rem;

    &--collapse {
      @media only screen and (max-width: 1500px) {
        display: none;
      }
    }

    &--collapse-employee {
      @media only screen and (max-width: 1700px) {
        display: none;
      }
    }

    img {
      width: 100%;
      height: 100%;
    }
  }

  &__separator {
    font-size: 2rem;
    margin-bottom: 0;

    @media only screen and (max-width: 1100px) {
      display: none;
    }
  }

  &__burger {
    display: none;

    @media only screen and (max-width: 1100px) {
      display: block;
    }
  }
}

.burger-menu {
  position: fixed;
  align-items: center;
  justify-content: center;
  top: 70px;
  width: 100%;
  z-index: 1000;
  background-color: white;
  display: none;
  @media only screen and (max-width: 1100px) {
    display: block;
  }
}

.top-bar {
  position: fixed;
  display: flex;
  align-items: center;
  top: 70px;
  left: 0;
  width: 100%;
  height: 60px;
  background-color: $primary-color;
  z-index: 500;
  padding-left: $content-padding;

  &__current-domain {
    color: white;
  }
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 100;
  display: none;
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  padding: 8px 0;
}

.dropdown-menu.open {
  display: block;
}


</style>
