import {createAPIClient} from './service.base';

const client = createAPIClient('/public-news-data/attachments/');

export const attachmentService = {
    downloadAttachment: async (attachmentId) => {
        const attachment = await client.get(`${attachmentId}/file/`, {responseType: 'blob'});
        return attachment;
    },
};
