import Vue from 'vue';
import createAuth0Client from '@auth0/auth0-spa-js';

const DEFAULT_REDIRECT_CALLBACK = () =>
    window.history.replaceState({}, document.title, window.location.pathname);

let instance;

export const getInstance = () => instance;

export const useAuth0 = ({
    onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
    redirectUri = window.location.origin,
    ...options
}) => {
    if (instance) return instance;

    instance = new Vue({
        data() {
            return {
                loading: true,
                isAuthenticated: false,
                user: {},
                auth0Client: null,
                popupOpen: false,
                error: null,
                tenant: null,
                // eslint-disable-next-line vue/no-reserved-keys
                __test_idToken: null,
            };
        },
        async created() {
            this.tenant = window.localStorage.getItem('auth-tenant');

            // use an id token from local storage for e2e tests
            this.__test_idToken = window.localStorage.getItem('__test_idToken');
            if (this.__test_idToken) {
                this.isAuthenticated = true; // await this.auth0Client.isAuthenticated();
                this.user = {
                    email_verified: true,
                    email: 'niko@alexjacobi.com',
                    family_name: 'Abeler',
                    given_name: 'Niko',
                    locale: 'en-GB',
                    name: 'Niko Abeler',
                    nickname: 'niko',
                    picture: 'https://lh3.googleusercontent.com/a-/AOh14GhkZd6QBbYF8cSafvgSXMtDpXQ7piYvGqQBBiEG2Q',
                    sub: 'google-oauth2|114918496499362898447',
                    updated_at: '2020-08-11T13:20:26.753Z',

                };
                this.loading = false;
                return;
            }

            this.auth0Client = await createAuth0Client({
                domain: options.domain,
                client_id: options.clientId,
                audience: options.audience,
                redirect_uri: redirectUri,
            });

            if (
                window.location.search.includes('error=') ||
                window.location.search.includes('error_description=')
            ) {
                const url = new URL(window.location);
                this.error = url.searchParams.get('error');
                this.error_description = url.searchParams.get('error_description');
                this.isAuthenticated = false;
                this.user = null;
                this.loading = false;
            } else {
                try {
                    if (
                        window.location.search.includes('code=') &&
                        window.location.search.includes('state=')
                    ) {
                        const {appState} = await this.auth0Client.handleRedirectCallback();
                        this.error = null;
                        onRedirectCallback(appState);
                    }
                } catch (error) {
                    this.error = error;
                } finally {
                    this.isAuthenticated = await this.auth0Client.isAuthenticated();
                    this.user = await this.auth0Client.getUser();
                    this.loading = false;
                }
            }
        },
        methods: {
            async loginWithPopup(o) {
                this.popupOpen = true;

                try {
                    await this.auth0Client.loginWithPopup(o);
                    this.user = await this.auth0Client.getUser();
                    this.isAuthenticated = await this.auth0Client.isAuthenticated();
                    this.error = null;
                } catch (error) {
                    this.error = error;
                } finally {
                    this.popupOpen = false;
                }
            },
            async handleRedirectCallback() {
                this.loading = true;
                try {
                    await this.auth0Client.handleRedirectCallback();
                    this.user = await this.auth0Client.getUser();
                    this.isAuthenticated = true;
                    this.error = null;
                } catch (e) {
                    this.error = e;
                } finally {
                    this.loading = false;
                }
            },
            loginWithRedirect(o) {
                return this.auth0Client.loginWithRedirect(o);
            },
            getIdTokenClaims(o) {
                return this.auth0Client.getIdTokenClaims(o);
            },
            getIdToken(o) {
                if (this.__test_idToken) {
                    return new Promise((resolve) => resolve(this.__test_idToken));
                }
                return this.getIdTokenClaims(o).then((c) => c.__raw);
            },
            getTokenSilently(o) {
                return this.auth0Client.getTokenSilently(o);
            },
            getTokenWithPopup(o) {
                return this.auth0Client.getTokenWithPopup(o);
            },
            logout(o) {
                this.removeTenant();
                return this.auth0Client.logout({
                    returnTo: process.env.VUE_APP_DOMAIN,
                });
            },
            changeTenant(id, name) {
                this.tenant = id;
                window.localStorage.setItem('auth-tenant', id);
                window.localStorage.setItem('auth-tenant-name', name);
            },
            removeTenant() {
                window.localStorage.removeItem('auth-tenant');
                window.localStorage.removeItem('auth-tenant-name');
            },
        },
    });

    return instance;
};

export const Auth0Plugin = {
    install(Vue, options) {
        Vue.prototype.$auth = useAuth0(options);
    },
};
