import Vue from 'vue';
import {agmaDashboardService} from '@/service/agma-dashboard.service.js';
import {agmaAnalyticsService} from '@/service/agma-analytics.service';

export default {
    namespaced: true,
    state() {
        return {
            overview: [],
            publisherPodcasts: [],
            publisherMonitor: [],
            podcast: [],
            currentTotal: 0,
            previousTotal: 0,
            queryParams: {
                podcast: [],
                publishers: [],
                analytics_windows: [],
            },
        };
    },
    mutations: {
        set: (state, data) => {
            Vue.set(state, data.key, data.value);
        },
    },
    actions: {
        fetchOverview: async (context, month) => {
            const overview = await agmaDashboardService.fetchOverview(month);
            context.commit('set', {key: 'overview', value: overview});
        },
        fetchPublisherPodcasts: async (context, filter) => {
            const publisherPodcasts = await agmaDashboardService.fetchPublisherPodcasts(filter.publisher, filter.month);
            const publisherMonitor = await agmaDashboardService.fetchPublisherMonitor(filter.publisher, filter.month);
            context.commit('set', {key: 'publisherPodcasts', value: publisherPodcasts});
            context.commit('set', {key: 'publisherMonitor', value: publisherMonitor});
        },
        fetchPodcast: async (context, filter) => {
            const window = context.state.queryParams.analytics_windows.find((x) => {
                return x.id === filter.month;
            });
            const currMonth = window.month;
            const currYear = window.year;
            let prevMonth = currMonth - 1;
            let prevYear = currYear;
            if (prevMonth < 1) {
                prevMonth = 12;
                prevYear -= 1;
            }
            const [
                podcast,
                currentTotal,
                previousTotal,
            ] = await Promise.all([
                agmaDashboardService.fetchPodcast(filter.podcast, filter.publisher, filter.month),
                agmaAnalyticsService.fetchPodcastTotals(
                    filter.publisher, filter.podcast, `?${new URLSearchParams({year: currYear, month: currMonth})}`,
                ),
                agmaAnalyticsService.fetchPodcastTotals(
                    filter.publisher, filter.podcast, `?${new URLSearchParams({year: prevYear, month: prevMonth})}`,
                ),
            ]);
            context.commit('set', {key: 'currentTotal', value: currentTotal.unique_downloads});
            context.commit('set', {key: 'previousTotal', value: previousTotal.unique_downloads});
            context.commit('set', {key: 'podcast', value: podcast});
        },
        fetchQueryParams: async (context, publisherId = '') => {
            const queryParams = await agmaDashboardService.fetchQueryParams(publisherId);
            context.commit('set', {key: 'queryParams', value: queryParams});
        },

        // EXPORTS
        fetchXLSX: async (context, filter = {}) => {
            const xlsx = await agmaDashboardService.fetchXLSX(filter);
            return xlsx;
        },
    },
    getters: {
        currentTotal: (state) => state.currentTotal,
        previousTotal: (state) => state.previousTotal,
        overview: (state) => state.overview,
        publisherPodcasts: (state) => state.publisherPodcasts,
        publisherMonitor: (state) => state.publisherMonitor,
        podcast: (state) => state.podcast,
        queryParams: (state) => state.queryParams,
    },
};
