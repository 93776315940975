import {createAPIClient} from './service.base';

// eslint-disable-next-line no-unused-vars
const client = createAPIClient('/auth/');

export const authService = {
    resendVerification: async () => {
        await client.post('resend_verification/');
    },
};
