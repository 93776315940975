// Store for the data of a single podcast. Used by the podcast-details view

import Vue from 'vue';
import {podcastsService} from '@/service/podcasts.service.js';

export default {
    namespaced: true,
    state() {
        return {
            id: '',
            title: '',
            name: '',
            genre: '',
            description: '',
            image_url: '',
            episodes: {
                data: [],
                count: null,
                total: null,
                page: null,
                pageCount: null,
            },
            episodes_count: 0,
            registeredOn: '',
            created_at: '',
            status: '',
            hoster: {
                name: '',
            },
        };
    },
    mutations: {
        set: (state, data) => {
            Vue.set(state, data.key, data.value);
        },
        setState: (state, data) => {
            Object.assign(state, data);
        },
    },
    actions: {
        fetchPodcast: async (context, {publisherId, id}) => {
            const podcast = await podcastsService.fetchPodcast(publisherId, id);
            context.commit('setState', podcast);
        },
        fetchEpisodes: async (context, {publisherId, id, filter}) => {
            const episodes = await podcastsService.fetchEpisodes(publisherId, id, filter);
            context.commit('set', {key: 'episodes', value: episodes});
        },
        updatePodcast: async (context, {publisherId, id, settings}) => {
            const updatedPodcast = await podcastsService.updatePodcast(publisherId, id, settings);
            context.commit('set', {key: 'name', value: updatedPodcast.name});
            context.commit('set', {key: 'effective_advertising', value: updatedPodcast.effective_advertising});
            context.commit('set', {key: 'public_analysis', value: updatedPodcast.public_analysis});
        },
        unregisterPodcast: async (context, {publisherId, id}) => {
            await podcastsService.unregisterPodcast(publisherId, id);
            context.commit('set', {key: 'status', value: 'canceled'});
        },
        cancelUnregisterPodcast: async (context, {publisherId, id}) => {
            await podcastsService.cancelUnregisterPodcast(publisherId, id);
            context.commit('set', {key: 'status', value: 'active'});
        },
        withdrawRegistration: async (context, {publisherId, id}) => {
            await podcastsService.withdrawRegistration(publisherId, id);
        },
    },
    getters: {
        podcast: (state) => state,
    },
};
