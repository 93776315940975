import {createAPIClient} from './service.base';

const client = createAPIClient('');

export const spotifyService = {
    fetchAnalyticsWindows: async () => {
        const windows = await client.get('/analytics-windows/');
        return windows.data;
    },
    fetchUploadedData: async (publisherId) => {
        const uploadedData = await client.get(`/publishers/${publisherId}/external_data/spotify/`);
        return uploadedData.data;
    },
    fetchSpotifyEligiblePodcasts: async (publisherId, windowId=null) => {
        const eligiblePodcasts = await client.get(`/publishers/${publisherId}/external_data/spotify/eligible_podcasts/`);
        return eligiblePodcasts.data;
    },
    uploadData: async (publisherId, data) => {
        const uploadedData = await client.post(`/publishers/${publisherId}/external_data/spotify/`, data);
        return uploadedData.data;
    },
    deteteData: async (publisherId, dataId) => {
        const deletedResponse = await client.delete(`/publishers/${publisherId}/external_data/spotify/${dataId}/`);
        return deletedResponse.data;
    },
};
