import {createAPIClient} from './service.base';

const listClient = createAPIClient('/list-marketers/');
const client = createAPIClient('/marketers/');
export const marketerService = {
    fetchMarketers: async (filter = '') => {
        const marketers = await listClient.get(filter);
        return marketers.data;
    },
    fetchPodcasts: async (marketerId, publisherId, filter = '') => {
        const podcasts = await client.get(`${marketerId}/publishers/${publisherId}/watched-podcasts/${filter}`);
        return podcasts.data;
    },
    registerPodcast: async (marketerId, publisherId, data) => {
        const registeredPodcast = await client.post(`${marketerId}/publishers/${publisherId}/watched-podcasts/`, data);
        return registeredPodcast.data;
    },
    fetchPublisherList: async (marketerId) => {
        const publishersList = await client.get(`${marketerId}/publishers/?sort=organisation`);
        return publishersList.data;
    },
    fetchPodcast: async (marketerId, publisherId, id) => {
        const podcast = await client.get(`${marketerId}/publishers/${publisherId}/watched-podcasts/${id}/`);
        return podcast.data;
    },
    fetchEpisodes: async (marketerId, publisherId, id, filter = '') => {
        const episodes = await client.get(`${marketerId}/publishers/${publisherId}/watched-podcasts/${id}/episodes/${filter}`);
        return episodes.data;
    },
    updatePodcast: async (marketerId, publisherId, id, data) => {
        const updatedPodcast = await client.patch(`${marketerId}/publishers/${publisherId}/watched-podcasts/${id}/`, data);
        return updatedPodcast.data;
    },
    unregisterPodcast: async (marketerId, publisherId, id) => {
        const unregisteredPodcast = await client.post(`${marketerId}/publishers/${publisherId}/watched-podcasts/${id}/deactivate_podcast/`);
        return unregisteredPodcast;
    },
    cancelUnregisterPodcast: async (marketerId, publisherId, id) => {
        const reregisteredPodcast = await client.post(`${marketerId}/publishers/${publisherId}/watched-podcasts/${id}/cancel_deactivate_podcast/`);
        return reregisteredPodcast;
    },
    withdrawRegistration: async (marketerId, publisherId, id) => {
        const deletedPodcast = await client.delete(`${marketerId}/publishers/${publisherId}/watched-podcasts/${id}/`);
        return deletedPodcast;
    },
};
