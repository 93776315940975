import Vue from 'vue';
import {personalAnalyticsService} from '@/service/personal-analytics.service.js';
import {publicAnalyticsService} from '@/service/public-analytics.service.js';
import {agmaAnalyticsService} from '@/service/agma-analytics.service.js';
import {previewAnalyticsService} from '@/service/preview-analytics.service.js';

export default {
    namespaced: true,
    state() {
        return {
            // INFO
            resultInfo: {},
            // PERSONAL ANALYTICS
            podcasts: {
                data: [],
                count: null,
                total: null,
                page: null,
                pageCount: null,
            },
            episodes: {
                data: [],
                count: null,
                total: null,
                page: null,
                pageCount: null,
            },
            publisherTotals: {},
            publisherLatestTotals: {},
            podcastTotals: {},
            episodeTotals: {},
            queryParams: {
                watched_podcasts: [],
            },
            // PUBLIC ANALYTICS
            publicPodcasts: {
                data: [],
                count: null,
                total: null,
                page: null,
                pageCount: null,
            },
            publicPodcastsForCategory: {
                aggregates: null,
                genres: [],
            },
            publicQueryParams: {
                publisher: [],
                windows: ['', '', ''],
            },
            publicTotal: null,
            // AGMA ANALYTICS
            publishers: [],
            // PREVIEW ANALYTICS
            previewPodcasts: {
                data: [],
                count: null,
                total: null,
                page: null,
                pageCount: null,
            },
            previewPodcastsForCategory: {
                aggregates: null,
                genres: [],
            },
            previewQueryParams: {
                publisher: [],
                windows: ['', '', ''],
            },
            previewTotal: null,
        };
    },
    mutations: {
        set: (state, data) => {
            Vue.set(state, data.key, data.value);
        },
        setState: (state, data) => {
            Object.assign(state, data);
        },
    },
    actions: {
        // INFO
        fetchResultInfo: async (context) => {
            const resultInfo = await publicAnalyticsService.fetchResultInfo();
            context.commit('set', {key: 'resultInfo', value: resultInfo});
        },

        // PERSONAL ANALYTICS
        fetchPodcasts: async (context, {publisherId, filter}) => {
            const podcasts = await personalAnalyticsService.fetchPodcasts(
                publisherId,
                filter,
            );
            context.commit('set', {key: 'podcasts', value: podcasts});
        },
        fetchEpisodes: async (context, {publisherId, filter}) => {
            const episodes = await personalAnalyticsService.fetchEpisodes(
                publisherId,
                filter,
            );
            context.commit('set', {key: 'episodes', value: episodes});
        },
        fetchPodcastTimeSeries: async (context, {publisherId, filter}) => {
            const podcastTimeSeries =
                await personalAnalyticsService.fetchPodcastTimeSeries(
                    publisherId,
                    filter,
                );
            context.commit('set', {
                key: 'podcastTimeSeries',
                value: podcastTimeSeries,
            });
        },
        fetchEpisodeTimeSeries: async (context, {publisherId, filter}) => {
            const episodeTimeSeries =
                await personalAnalyticsService.fetchEpisodeTimeSeries(
                    publisherId,
                    filter,
                );
            context.commit('set', {
                key: 'episodeTimeSeries',
                value: episodeTimeSeries,
            });
        },
        fetchPublisherTotals: async (context, {publisherId, filter} = '') => {
            const publisherTotals =
                await personalAnalyticsService.fetchPublisherTotals(
                    publisherId,
                    filter,
                );
            context.commit('set', {
                key: 'publisherTotals',
                value: publisherTotals,
            });
        },
        fetchPublisherLatestTotals: async (context, {publisherId}) => {
            const publisherLatestTotals =
                await personalAnalyticsService.fetchPublisherLatestTotals(
                    publisherId,
                );
            context.commit('set', {
                key: 'publisherLatestTotals',
                value: publisherLatestTotals,
            });
        },
        fetchPodcastTotals: async (context, {publisherId, id, params}) => {
            const podcastTotals =
                await personalAnalyticsService.fetchPodcastTotals(
                    publisherId,
                    id,
                    params,
                );
            context.commit('set', {
                key: 'podcastTotals',
                value: podcastTotals,
            });
        },
        fetchEpisodeTotals: async (context, {publisherId, id, params}) => {
            const episodeTotals =
                await personalAnalyticsService.fetchEpisodeTotals(
                    publisherId,
                    id,
                    params,
                );
            context.commit('set', {
                key: 'episodeTotals',
                value: episodeTotals,
            });
        },
        fetchQueryParams: async (context, {publisherId}) => {
            const queryParams = await personalAnalyticsService.fetchQueryParams(
                publisherId,
            );
            context.commit('set', {key: 'queryParams', value: queryParams});
        },
        fetchCSV: async (context, {publisherId, filter}) => {
            const csv = await personalAnalyticsService.fetchCSV(
                publisherId,
                filter,
            );
            return csv;
        },
        fetchXLSX: async (context, {publisherId, filter}) => {
            const xlsx = await personalAnalyticsService.fetchXLSX(
                publisherId,
                filter,
            );
            return xlsx;
        },

        // PUBLIC ANALYTICS
        fetchPublicPodcasts: async (context, filter) => {
            const publicPodcasts = await publicAnalyticsService.fetchPodcasts(
                filter,
            );
            context.commit('set', {
                key: 'publicPodcasts',
                value: publicPodcasts,
            });
        },
        fetchPublicPodcastsForCategory: async (context, filter) => {
            const publicPodcastsForCategory = await publicAnalyticsService.fetchPodcastsForCategory(
                filter,
            );

            context.commit('set', {
                key: 'publicPodcastsForCategory',
                value: publicPodcastsForCategory,
            });
        },
        fetchPublicQueryParams: async (context) => {
            const queryParams = await publicAnalyticsService.fetchQueryParams();
            context.commit('set', {
                key: 'publicQueryParams',
                value: queryParams,
            });
        },
        fetchPublicTotal: async (context, filter) => {
            const publicTotal = await publicAnalyticsService.fetchTotal(filter);
            context.commit('set', {key: 'publicTotal', value: publicTotal});
        },
        fetchPublicCSV: async (context, query) => {
            const csv = await publicAnalyticsService.fetchCSV(query);
            return csv;
        },
        fetchPublicXLSX: async (context, query) => {
            const xlsx = await publicAnalyticsService.fetchXLSX(query);
            return xlsx;
        },
        fetchPublicCategoryXLSX: async (context, query) => {
            const xlsx = await publicAnalyticsService.fetchCategoryXLSX(query);
            return xlsx;
        },
        fetchPublicCategoryCSV: async (context, query) => {
            const csv = await publicAnalyticsService.fetchCategoryCSV(query);
            return csv;
        },

        // AGMA ANALYTICS
        fetchPublishers: async (context) => {
            const publishers = await agmaAnalyticsService.fetchPublishers();
            context.commit('set', {key: 'publishers', value: publishers});
        },
        fetchAgmaPodcasts: async (context, {publisherId, filter}) => {
            const podcasts = await agmaAnalyticsService.fetchPodcasts(
                publisherId,
                filter,
            );
            context.commit('set', {key: 'podcasts', value: podcasts});
        },
        fetchAgmaEpisodes: async (context, {publisherId, filter}) => {
            const episodes = await agmaAnalyticsService.fetchEpisodes(
                publisherId,
                filter,
            );
            context.commit('set', {key: 'episodes', value: episodes});
        },
        fetchAgmaPodcastTimeSeries: async (
            context,
            {publisherId, filter},
        ) => {
            const podcastTimeSeries =
                await agmaAnalyticsService.fetchPodcastTimeSeries(
                    publisherId,
                    filter,
                );
            context.commit('set', {
                key: 'podcastTimeSeries',
                value: podcastTimeSeries,
            });
        },
        fetchAgmaEpisodeTimeSeries: async (
            context,
            {publisherId, filter},
        ) => {
            const episodeTimeSeries =
                await agmaAnalyticsService.fetchEpisodeTimeSeries(
                    publisherId,
                    filter,
                );
            context.commit('set', {
                key: 'episodeTimeSeries',
                value: episodeTimeSeries,
            });
        },
        fetchAgmaPublisherTotals: async (
            context,
            {publisherId, filter = ''},
        ) => {
            const publisherTotals =
                await agmaAnalyticsService.fetchPublisherTotals(
                    publisherId,
                    filter,
                );
            context.commit('set', {
                key: 'publisherTotals',
                value: publisherTotals,
            });
        },
        fetchAgmaPublisherLatestTotals: async (context, publisherId) => {
            const publisherLatestTotals =
                await agmaAnalyticsService.fetchPublisherLatestTotals(
                    publisherId,
                );
            context.commit('set', {
                key: 'publisherLatestTotals',
                value: publisherLatestTotals,
            });
        },
        fetchAgmaPodcastTotals: async (context, {publisherId, filter}) => {
            const podcastTotals = await agmaAnalyticsService.fetchPodcastTotals(
                publisherId,
                filter.id,
                filter.params,
            );
            context.commit('set', {
                key: 'podcastTotals',
                value: podcastTotals,
            });
        },
        fetchAgmaEpisodeTotals: async (context, {publisherId, filter}) => {
            const episodeTotals = await agmaAnalyticsService.fetchEpisodeTotals(
                publisherId,
                filter.id,
                filter.params,
            );
            context.commit('set', {
                key: 'episodeTotals',
                value: episodeTotals,
            });
        },
        fetchAgmaQueryParams: async (context, publisherId) => {
            const queryParams = await agmaAnalyticsService.fetchQueryParams(
                publisherId,
            );
            context.commit('set', {key: 'queryParams', value: queryParams});
        },
        fetchAgmaCSV: async (context, {publisherId, filter}) => {
            const csv = await agmaAnalyticsService.fetchCSV(
                publisherId,
                filter,
            );
            return csv;
        },

        // PREVIEW ANALYTICS
        fetchPreviewPodcasts: async (context, filter) => {
            const previewPodcasts = await previewAnalyticsService.fetchPodcasts(
                filter,
            );
            context.commit('set', {
                key: 'previewPodcasts',
                value: previewPodcasts,
            });
        },
        fetchPreviewPodcastsForCategory: async (context, filter) => {
            const previewPodcastsForCategory = await previewAnalyticsService.fetchPodcastsForCategory(
                filter,
            );

            context.commit('set', {
                key: 'previewPodcastsForCategory',
                value: previewPodcastsForCategory,
            });
        },
        fetchPreviewQueryParams: async (context) => {
            const previewQueryParams =
                await previewAnalyticsService.fetchQueryParams();
            context.commit('set', {
                key: 'previewQueryParams',
                value: previewQueryParams,
            });
        },
        fetchPreviewTotal: async (context, filter) => {
            const previewTotal = await previewAnalyticsService.fetchTotal(
                filter,
            );
            context.commit('set', {key: 'previewTotal', value: previewTotal});
        },
        fetchPreviewCSV: async (context, query) => {
            const csv = await previewAnalyticsService.fetchCSV(query);
            return csv;
        },
        fetchPreviewXLSX: async (context, query) => {
            const xlsx = await previewAnalyticsService.fetchXLSX(query);
            return xlsx;
        },
        fetchPreviewCategoryXLSX: async (context, query) => {
            const xlsx = await previewAnalyticsService.fetchCategoryXLSX(query);
            return xlsx;
        },
        fetchPreviewCategoryCSV: async (context, query) => {
            const csv = await previewAnalyticsService.fetchCategoryCSV(query);
            return csv;
        },
    },
    getters: {
        // INFO
        resultInfo: (state) => state.resultInfo,

        // PERSONAL ANALYTICS
        episodes: (state) => state.episodes,
        episodeTimeSeries: (state) => state.episodeTimeSeries,
        podcasts: (state) => state.podcasts,
        publisherLatestTotals: (state) => state.publisherLatestTotals,
        podcastTotals: (state) => state.podcastTotals,
        episodeTotals: (state) => state.episodeTotals,
        podcastTimeSeries: (state) => state.podcastTimeSeries,
        allPodcasts: (state) => state.queryParams.watched_podcasts,
        publisherTotals: (state) => state.publisherTotals,
        queryParams: (state) => state.queryParams,

        // PUBLIC ANALYTICS
        publicPodcasts: (state) => state.publicPodcasts,
        publicPodcastsForCategory: (state) => state.publicPodcastsForCategory,
        publicQueryParams: (state) => state.publicQueryParams,
        publicTotal: (state) => state.publicTotal,

        // AGMA ANALYTICS
        publishers: (state) => state.publishers,

        // PREVIEW ANALYTICS
        previewPodcasts: (state) => state.previewPodcasts,
        previewPodcastsForCategory: (state) => state.previewPodcastsForCategory,
        previewQueryParams: (state) => state.previewQueryParams,
        previewTotal: (state) => state.previewTotal,
    },
};
