// Breadcrumbs that are used on every view on the top. They show where the user currently is on the site and allow them go back

<template>
    <nav class="breadcrumbs">
        <ol>
            <li
                v-for="(crumb, index) in crumbs"
                :key="index"
            >
                <button
                    class="breadcrumbs__crumb"
                    :class="{ 'breadcrumbs__crumb--disabled': isLast(index) }"
                    :style="styleVariables"
                    @click="goTo(crumb.to.name)"
                >
                    {{ crumb.text }}
                </button>
            </li>
        </ol>
    </nav>
</template>

<script>
export default {
    name: 'Breadrcumbs',
    props: {
        crumbs: {type: Array, required: false, default: () => []},
        textColor: {type: String, required: false, default: '#6EB1A4'},
    },
    computed: {
        styleVariables() {
            return {
                '--text-color': this.$props.textColor,
            };
        },
    },
    methods: {
        /** Checks if the the index is the last one in the list of crumbs. If it is, then the crumb is disabled and cannot be clicked, otherwise it leads to a different view
         * @param {number} index index which will be checked
         * @return {Boolean} whether the index is the last one or not
         */
        isLast(index) {
            return index === this.crumbs.length - 1;
        },
        goTo(targetName) {
            if (targetName!==null) {
                this.$router.push({name: targetName});
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.breadcrumbs {
    ol {
        display: flex;
        list-style: none;
        padding: 0;
    }

    li:not(:last-child):after {
        content: ' / ';
        white-space: pre;
    }

    &__crumb {
        background-color: transparent;
        border: none;
        color: var(--text-color);
        cursor: pointer;
        font-size: 1.5rem;

        &--disabled {
            pointer-events: none;
            color: grey;
        }
    }
}
</style>
