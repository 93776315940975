import axios from 'axios';
import {getInstance} from '@/auth/auth.wrapper';
import urlJoin from 'url-join';

export const createAPIClient = (baseURL) => {
    const client = axios.create({
        baseURL: urlJoin(process.env.VUE_APP_API_URL, baseURL),
    });
    client.interceptors.request.use(
        async (config) => {
            const instance = getInstance();
            config.headers['Authorization'] = 'Bearer ' + await instance.getIdToken();
            return config;
        },
    );
    return client;
};
