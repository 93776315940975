import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store/index.js';
import vuetify from './plugins/vuetify';
import i18n from './plugins/i18n.js';
import {Auth0Plugin} from './auth';
import './assets/css/main.scss';
import VueShowdown from 'vue-showdown';

// allow debugging in browser
Vue.config.devtools = true;
// set constant for frontend (e.g. base url for mp3s, images ...)
Vue.prototype.$mediaUrl = process.env.VUE_APP_API_URL;
Vue.prototype.$domain = process.env.VUE_APP_DOMAIN;
Vue.prototype.$maxFeeds = process.env.VUE_APP_MAX_FEEDS || 10;

Vue.mixin({
    methods: {
        hasRole(needed) {
            /**
             * returns true if the user has at least one of the roles
             */

            const roleType = this.$route.params.roleType;
            for (let i = 0; i < needed.length; i++) {
                const need = needed[i];
                if (roleType === need) {
                    return true;
                }
            }
            return false;
        },
    },
});

const applyRoles = (el, binding, vnode) => {
    /**
    * Possible Roles:
    * hoster
    * publisher
    * media_agency
     */
    el.style.display = '';

    if (vnode.context.$route.params.roleType) {
        const needed = Object.keys(binding.modifiers);
        if (vnode.context.hasRole(needed)) return;
    }
    if (binding.arg === 'view') {
        vnode.context.$router.push({name: 'forbidden'});
    } else {
        el.style.display = 'none';
    }
};

Vue.directive('roles', {
    bind: applyRoles,
    update: applyRoles,
    inserted: applyRoles,
    componentUpdated: applyRoles,
});

Vue.use(Auth0Plugin, {
    domain: process.env.VUE_APP_AUTH0_DOMAIN,
    clientId: process.env.VUE_APP_AUTH0_CLIENT_ID,
    audience: process.env.VUE_APP_AUTH0_AUDIENCE,
    redirectUri: process.env.VUE_APP_REDIRECT_URI,
    onRedirectCallback: (appState) => {
        router.push(
            appState && appState.targetUrl ?
                appState.targetUrl :
                window.location.pathname,
        ).catch(() => { });
    },
});

Vue.use(VueShowdown, {
    // set default flavor of showdown
    flavor: 'github',
    // set default options of showdown (will override the flavor options)
    options: {
        emoji: false,
    },
});

new Vue({
    vuetify,
    i18n,
    router,
    store,
    render: (h) => h(App),
}).$mount('#app');
