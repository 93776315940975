import colors from 'vuetify/lib/util/colors';

export default {
    'primary': {
        base: '#5050ff',
    },
    'secondary': colors.indigo,
    'tertiary': colors.pink.base,
    'button-color': '#5050ff',
    'button-text-color': '#ffffff',
    'create-button-color': '#0013ba',
    'cancel-button-text-color': '#505064',
    'tile-color': '#d4dcb2',
    'form-card-color': '#ffffff',
    'form-input-color': '#ffffff',
    'form-input-color-2': '#e1e1ea',
    'sorting-color-1': '#ffffff',
    'sorting-color-2': '#d8d8d8',
    'title-color': '#ff0',
    'bar-color-episodes': '#1f1f49',
    'bar-color-stories': '#db3259',
    'bar-color-admin': '#4a94a5',
    'bar-color-analytics': '#ff3379',
    'bar-color-campaigns': '#ff9600',
    'bar-color-home': '#5050ff',
    'pink': '#ff3379',
    'text-grey': '#4a4a4a',
    'button-blue': '#5050ff',
    'button-grey': 'd8d8d8',
    'addRed': '#ff3379',

    'tile-width': '215px',

    'button-action': '#ff3379',
    'button-primary': '#5050ff',
    'button-normal': '#d8d8d8',
    'footer-background': '#d8d8d8',
    'footer-link-color': '#4a4a4a',
};
