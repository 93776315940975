// Store for the profile data of the user. Used by the profile view

import Vue from 'vue';
import {userService} from '@/service/user.service.js';

export default {
    namespaced: true,
    state() {
        return {
            data: {
                id: '',
                name: '',
                public: false,
                token: '',
                upload_api_url: '',
                email: '',
                registeredPublishers: '',
            },
        };
    },
    mutations: {
        set: (state, data) => {
            Vue.set(state, data.key, data.value);
        },
    },
    actions: {

        /**
         * Gets publisher / hoster data depending on the roles of the currently logged in user
         * @param {*} context
         * @param {String} id id of the hoster being fetched
         *
         */
        fetchHosterData: async (context, id) => {
            if (context.state.data.name === '') {
                const hosterData = await userService.fetchHosterData(id);
                hosterData['registeredPublishers'] = await userService.fetchRegisteredPublishers(id);
                context.commit('set', {key: 'data', value: hosterData});
            }
        },

        /**
         * Fetches the role of the currently logged in user.
         * @param {*} context
         * @return {Object} user object
         */
        fetchUser: async (context) => {
            const user = await userService.fetchUser();
            context.commit('set', {key: 'user', value: user});
            return user;
        },

        updatePublisherData: async (context, data) => {
            const updatedPublisherData = await userService.updatePublisherData(data);
            context.commit('set', {key: 'publisherData', value: updatedPublisherData});
        },
    },
    getters: {
        publisherData: (state) => state.publisherData,
        data: (state) => {
            return state.data;
        },
    },
};
