import Vue from 'vue';
import {personalAnalyticsService} from '@/service/personal-analytics.service.js';

export default {
    namespaced: true,
    state() {
        return {
            // PERSONAL ANALYTICS
            podcasts: {
                data: [],
                count: null,
                total: null,
                page: null,
                pageCount: null,
            },
            episodes: {
                data: [],
                count: null,
                total: null,
                page: null,
                pageCount: null,
            },
            publisherTotals: {},
            publisherLatestTotals: {},
            podcastTotals: {},
            episodeTotals: {},
            queryParams: {
                watched_podcasts: [],
            },
        };
    },
    mutations: {
        set: (state, data) => {
            Vue.set(state, data.key, data.value);
        },
        setState: (state, data) => {
            Object.assign(state, data);
        },
    },
    actions: {

        // PERSONAL ANALYTICS
        fetchPodcasts: async (context, {marketerId, publisherId, filter}) => {
            const podcasts = await personalAnalyticsService.marketerFetchPodcasts(marketerId, publisherId, filter);
            context.commit('set', {key: 'podcasts', value: podcasts});
        },
        fetchEpisodes: async (context, {marketerId, publisherId, filter}) => {
            const episodes = await personalAnalyticsService.marketerFetchEpisodes(marketerId, publisherId, filter);
            context.commit('set', {key: 'episodes', value: episodes});
        },
        fetchPodcastTimeSeries: async (context, {marketerId, publisherId, filter}) => {
            const podcastTimeSeries = await personalAnalyticsService.marketerFetchPodcastTimeSeries(marketerId, publisherId, filter);
            context.commit('set', {key: 'podcastTimeSeries', value: podcastTimeSeries});
        },
        fetchEpisodeTimeSeries: async (context, {marketerId, publisherId, filter}) => {
            const episodeTimeSeries = await personalAnalyticsService.marketerFetchEpisodeTimeSeries(marketerId, publisherId, filter);
            context.commit('set', {key: 'episodeTimeSeries', value: episodeTimeSeries});
        },
        fetchPublisherTotals: async (context, {marketerId, publisherId, filter} = '') => {
            const publisherTotals = await personalAnalyticsService.marketerFetchPublisherTotals(marketerId, publisherId, filter);
            context.commit('set', {key: 'publisherTotals', value: publisherTotals});
        },
        fetchPublisherLatestTotals: async (context, {marketerId, publisherId}) => {
            const publisherLatestTotals = await personalAnalyticsService.marketerFetchPublisherLatestTotals(marketerId, publisherId);
            context.commit('set', {key: 'publisherLatestTotals', value: publisherLatestTotals});
        },
        fetchPodcastTotals: async (context, {marketerId, publisherId, id, params}) => {
            const podcastTotals = await personalAnalyticsService.marketerFetchPodcastTotals(marketerId, publisherId, id, params);
            context.commit('set', {key: 'podcastTotals', value: podcastTotals});
        },
        fetchEpisodeTotals: async (context, {marketerId, publisherId, id, params}) => {
            const episodeTotals = await personalAnalyticsService.marketerFetchEpisodeTotals(marketerId, publisherId, id, params);
            context.commit('set', {key: 'episodeTotals', value: episodeTotals});
        },
        fetchQueryParams: async (context, {marketerId, publisherId}) => {
            const queryParams = await personalAnalyticsService.marketerFetchQueryParams(marketerId, publisherId);
            context.commit('set', {key: 'queryParams', value: queryParams});
        },
        fetchCSV: async (context, {marketerId, publisherId, filter}) => {
            const csv = await personalAnalyticsService.marketerFetchCSV(marketerId, publisherId, filter);
            return csv;
        },
        fetchXLSX: async (context, {marketerId, publisherId, filter}) => {
            const xlsx = await personalAnalyticsService.marketerFetchXLSX(marketerId, publisherId, filter);
            return xlsx;
        },


    },
    getters: {

        // PERSONAL ANALYTICS
        episodes: (state) => state.episodes,
        episodeTimeSeries: (state) => state.episodeTimeSeries,
        podcasts: (state) => state.podcasts,
        publisherLatestTotals: (state) => state.publisherLatestTotals,
        podcastTotals: (state) => state.podcastTotals,
        episodeTotals: (state) => state.episodeTotals,
        podcastTimeSeries: (state) => state.podcastTimeSeries,
        allPodcasts: (state) => state.queryParams.watched_podcasts,
        publisherTotals: (state) => state.publisherTotals,
        queryParams: (state) => state.queryParams,

    },
};
