import Vue from 'vue';
import Vuex from 'vuex';
import user from '@/store/stores/user.store.js';
import podcasts from '@/store/stores/podcasts.store.js';
import podcast from '@/store/stores/podcast.store.js';
import hosters from '@/store/stores/hosters.store.js';
import marketers from '@/store/stores/marketers.store.js';
import marketerPodcast from '@/store/stores/marketer-podcast.store.js';
import marketerAnalytics from '@/store/stores/marketer-analytics.store.js';
import analytics from '@/store/stores/analytics.store.js';
import logfiles from '@/store/stores/logfiles.store.js';
import agmaDashboard from '@/store/stores/agma-dashboard.store.js';
import agmaSpotify from '@/store/stores/agma-spotify.store.js';
import userHoster from '@/store/stores/user-hoster.store.js';
import userPublisher from '@/store/stores/user-publisher.store.js';
import spotify from '@/store/stores/spotify.store.js';
import news from '@/store/stores/news.store.js';
import {createAuthModule} from '@/auth/auth.module.js';
import {authService} from '../service/auth.service';
import {ROLES_APP_NAME} from '@/common/constants';


// Vuex in Devtools
Vue.config.devtools = true;
Vue.use(Vuex);

/**
 * Store the state of the App
 * Only the store should load data from external resources
 */
export default new Vuex.Store({
    // globally used modules. Single instance
    modules: {
        user,
        podcasts,
        podcast,
        analytics,
        hosters,
        marketers,
        marketerPodcast,
        marketerAnalytics,
        logfiles,
        agmaDashboard,
        agmaSpotify,
        userHoster,
        userPublisher,
        spotify,
        news,
        auth: createAuthModule(authService, ROLES_APP_NAME),
    },
    state: {
        error: '',
        success: '',
        crumbs: [],
    },
    mutations: {
        setError: (state, errorMessage) => {
            Vue.set(state, 'success', '');
            Vue.set(state, 'error', errorMessage);
        },
        setSuccess: (state, successMessage) => {
            Vue.set(state, 'error', '');
            Vue.set(state, 'success', successMessage);
            setTimeout(() => {
                Vue.set(state, 'success', '');
            }, 5000);
        },
        clearInfoMessages: (state) => {
            Vue.set(state, 'success', '');
            Vue.set(state, 'error', '');
        },
        setCrumbs: (state, crumbs) => {
            Vue.set(state, 'crumbs', crumbs);
        },
    },
    actions: {},
});
