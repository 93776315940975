import {getInstance} from '@/auth/auth.wrapper';

export default {
    methods: {
        /**
         * Navigates to a different view
         * @param {String} targetname name of the target view
         * @param {Object} params params that are use for the navigation, here it would typically contain the podcastId
         */
        goTo(targetname, params = {}) {
            this.$router.push({
                name: targetname,
                params: params,
            }).catch(() => { });
        },
        /**
         * Redirects to a specified website URL.
         *
         * @param {string} url - The URL of the website to redirect to.
         * @param {boolean} newTab - Optional. Specifies whether to open the URL in a new tab. Default is false.
         * @param {boolean} token - Optional. Specifies whether to add token to the URL or not.
         */
        async redirectToWebsite(url, newTab = false, token=false) {
            const instance = getInstance();
            const authToken = await instance.getTokenSilently();
            if (token) {
                url += '?access_token=' + authToken;
            }

            if (newTab) {
                window.open(url, '_blank');
            } else {
                window.location.href = url;
            }
        },
        contentType(ext) {
            let ctype = null;
            if (ext === 'png') {
                ctype = 'image/png';
            } else if (ext === 'jpg' | ext === 'jpeg') {
                ctype = 'image/jpeg';
            } else if (ext === 'bmp') {
                ctype = 'image/bmp';
            } else if (ext === 'gif') {
                ctype = 'image/gif';
            } else if (ext === 'csv') {
                ctype = 'text/csv';
            } else if (ext === 'pdf') {
                ctype = 'application/pdf';
            } else if (ext === 'xlsx') {
                ctype = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            } else {
                ctype = 'application/octet-stream';
            }
            return ctype;
        },
    },
};
