// Store for the profile data of the user. Used by the profile view

import Vue from 'vue';
import {userService} from '@/service/user.service.js';

export default {
    namespaced: true,
    state() {
        return {
            data: {
                id: '',
                name: '',
                public: false,
                token: '',
                upload_api_url: '',
                email: '',
            },
        };
    },
    mutations: {
        set: (state, data) => {
            Vue.set(state, data.key, data.value);
        },
    },
    actions: {

        /**
         * Gets publisher / publisher data depending on the roles of the currently logged in user
         * @param {*} context
         * @param {String} id id of the publisher being fetched
         *
         */
        fetchPublisher: async (context, {id}) => {
            const publisherData = await userService.fetchPublisherData(id);
            context.commit('set', {key: 'data', value: publisherData});
        },

        updatePublisherData: async (context, {id, data}) => {
            const updatedPublisherData = await userService.updatePublisherData(id, data);
            context.commit('set', {key: 'data', value: updatedPublisherData});
        },
    },
    getters: {
        publisherData: (state) => state.publisherData,
        data: (state) => {
            return state.data;
        },
    },
};
