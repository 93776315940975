import Vue from 'vue';
import {logfilesService} from '@/service/logfiles.service.js';

export default {
    namespaced: true,
    state() {
        return {
            data: [],
            count: null,
            total: null,
            page: null,
            pageCount: null,
        };
    },
    mutations: {
        set: (state, data) => {
            Vue.set(state, data.key, data.value);
        },
        setState: (state, data) => {
            Object.assign(state, data);
        },
    },
    actions: {
        fetchLogfiles: async (context, {hosterId, filter}) => {
            const logfiles = await logfilesService.fetchLogfiles(hosterId, filter);
            context.commit('setState', logfiles);
            return logfiles;
        },
        deleteLogfile: async (context, {hosterId, id}) => {
            await logfilesService.deleteLogfile(hosterId, id);
        },
    },
    getters: {
        logfiles: (state) => state,
    },
};
