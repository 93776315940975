import VueI18n from 'vue-i18n';
import Vue from 'vue';
import en from './../lang/en.json';
import de from './../lang/de.json';

export const DEFAULT_LANGUAGE = 'de';
export const FALLBACK_LANGUAGE = 'de';
export const SUPPORTED_LANGUAGES = ['de'];

export const DATETIME = {
    'en': {
        short: {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
        },
        long: {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            weekday: 'short',
            hour: 'numeric',
            minute: 'numeric',
        },
    },
    'de': {
        short: {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
        },
        long: {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            weekday: 'short',
            hour: '2-digit',
            minute: '2-digit',
        },
    },
};

Vue.use(VueI18n);
export const i18n = new VueI18n({
    // set locale
    locale: process.env.VUE_APP_I18N_LOCALE || FALLBACK_LANGUAGE,
    fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || FALLBACK_LANGUAGE,
    // strings that can be used globally
    silentFallbackWarn: true,
    messages: {en, de},
    dateTimeFormats: DATETIME,
});
export default i18n;
