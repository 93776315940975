// Store for the data of a single podcast. Used by the podcast-details view

import Vue from 'vue';
import {hostersService} from '@/service/hoster.service.js';

export default {
    namespaced: true,
    state() {
        return {
            hosters: [],
        };
    },
    mutations: {
        setHosters: (state, data) => {
            Vue.set(state, 'hosters', data);
        },
    },
    actions: {
        fetchHosters: async (context) => {
            const hosters = await hostersService.fetchHosters('?limit=100');
            context.commit('setHosters', hosters.data);
        },
    },
    getters: {
        hosters: (state) => state.hosters,
    },
};
