import {createAPIClient} from './service.base';

const client = createAPIClient('/admin/preview-public-analysis/');

export const previewAnalyticsService = {
    fetchPodcasts: async (filter = '') => {
        const podcasts = await client.get(filter);
        return podcasts.data;
    },
    fetchPodcastsForCategory: async (filter = '') =>{
        const podcastsForCategory= await client.get(`list_categories/${filter}`);
        return podcastsForCategory.data;
    },
    fetchQueryParams: async () => {
        const queryParams = await client.get('query-params');
        return queryParams.data;
    },
    fetchTotal: async (filter = '') => {
        const total = await client.get(`latest_total/${filter}`);
        return total.data;
    },
    fetchCSV: async (query) => {
        const csv = await client.get(`csv/?${new URLSearchParams(query)}`);
        return csv.data;
    },
    fetchXLSX: async (query) => {
        const xlsx = await client.get(`xlsx/?${new URLSearchParams(query)}`, {
            responseType: 'blob',
        });
        return xlsx.data;
    },
    fetchCategoryXLSX: async (query) => {
        const xlsx = await client.get(
            `xlsx_categories/?${new URLSearchParams(query)}`,
            {responseType: 'blob'},
        );
        return xlsx.data;
    },
    fetchCategoryCSV: async (query) => {
        const csv = await client.get(
            `csv_categories/?${new URLSearchParams(query)}`);
        return csv.data;
    },
};
