import Vue from 'vue';
import Router from 'vue-router';
// import {i18n, SUPPORTED_LANGUAGES} from './plugins/i18n';
import {authGuard} from './auth';

Vue.use(Router);


const router = new Router({
    base: '/app',
    mode: 'history',
    scrollBehavior(to, from, savedPosition) {
        return {x: 0, y: 0};
    },
    routes: [
        // GENERAL
        {
            path: '',
            name: 'role-selection',
            component: () => import('@/views/role-selection.view'),
            beforeEnter: authGuard,
        },
        {
            path: '/:roleType/:roleId/dashboard',
            name: 'dashboard',
            component: () => import('@/views/dashboard.view'),
            beforeEnter: authGuard,
        },
        {
            path: '/:roleType/:roleId/imprint',
            name: 'imprint',
            component: () => import('@/views/misc/imprint.view'),
            beforeEnter: authGuard,
        },
        {
            path: '/:roleType/:roleId/news',
            name: 'news',
            component: () => import('@/views/news.view'),
            beforeEnter: authGuard,
        },
        // HOSTER / PUBLISHER
        {
            path: '/:roleType/:roleId/publisher-settings',
            name: 'publisher-settings',
            component: () => import('@/views/publisher-settings.view'),
            beforeEnter: authGuard,
        },
        {
            path: '/:roleType/:roleId/profile',
            name: 'hoster-profile',
            component: () => import('@/views/hosterProfile.view'),
            beforeEnter: authGuard,
        },
        {
            path: '/:roleType/:roleId/podcasts',
            name: 'podcasts',
            component: () => import('@/views/podcasts.view'),
            beforeEnter: authGuard,
        },
        {
            path: '/:roleType/:roleId/spotify-csv-upload',
            name: 'spotify-csv-upload',
            component: () => import('@/views/spotify-csv-upload.view'),
            beforeEnter: authGuard,
        },
        {
            path: '/:roleType/:roleId/podcasts/:podcastId',
            name: 'podcast-details',
            component: () => import('@/views/podcast-details.view'),
            beforeEnter: authGuard,
        },
        {
            path: '/:roleType/:roleId/personal-analysis',
            name: 'personal-analysis',
            component: () => import('@/views/personal-analysis.view'),
            beforeEnter: authGuard,
        },
        {
            path: '/:roleType/:roleId/personal-analysis-overview',
            name: 'personal-analysis-overview',
            component: () => import('@/views/personal-analysis-overview.view'),
            beforeEnter: authGuard,
        },
        {
            path: '/:roleType/:roleId/public-analysis',
            name: 'public-analysis',
            component: () => import('@/views/public-analysis.view'),
            beforeEnter: authGuard,
        },
        {
            path: '/:roleType/:roleId/logfiles',
            name: 'logfiles',
            component: () => import('@/views/logfiles.view'),
            beforeEnter: authGuard,
        },
        // Marketer
        {
            path: '/:roleType/:roleId/publisher-selection-podcasts',
            name: 'marketer-publisher-selection-podcasts',
            component: () => import('@/views/marketer-publisher-selection.view'),
            beforeEnter: authGuard,
        },
        {
            path: '/:roleType/:roleId/publishers/:publisherId/podcasts',
            name: 'marketer-podcasts',
            component: () => import('@/views/podcasts.view'),
            beforeEnter: authGuard,
        },
        {
            path: '/:roleType/:roleId/publishers/:publisherId/podcasts/:podcastId',
            name: 'marketer-podcast-details',
            component: () => import('@/views/podcast-details.view'),
            beforeEnter: authGuard,
        },
        {
            path: '/:roleType/:roleId/publishers/:publisherId/public-analysis',
            name: 'marketer-analysis',
            component: () => import('@/views/personal-analysis.view'),
            beforeEnter: authGuard,
        },
        {
            path: '/:roleType/:roleId/publisher-selection-analysis',
            name: 'marketer-publisher-selection-analysis',
            component: () => import('@/views/marketer-publisher-selection.view'),
            beforeEnter: authGuard,
        },
        // AGMA
        {
            path: '/:roleType/:roleId/agma-overview',
            name: 'agma-overview',
            component: () => import('@/views/agma-overview.view'),
            beforeEnter: authGuard,
        },
        {
            path: '/:roleType/:roleId/agma-publisher',
            name: 'agma-publisher',
            component: () => import('@/views/agma-publisher.view'),
            beforeEnter: authGuard,
        },
        {
            path: '/:roleType/:roleId/agma-podcasts',
            name: 'agma-podcasts',
            component: () => import('@/views/agma-podcasts.view'),
            beforeEnter: authGuard,
        },
        {
            path: '/:roleType/:roleId/agma-analysis-overview',
            name: 'agma-analysis-overview',
            component: () => import('@/views/agma-analysis-overview.view'),
            beforeEnter: authGuard,
        },
        {
            path: '/:roleType/:roleId/agma-analysis/:publisher',
            name: 'agma-analysis',
            component: () => import('@/views/agma-analysis.view.vue'),
            beforeEnter: authGuard,
        },
        {
            path: '/:roleType/:roleId/agma-spotify',
            name: 'agma-spotify',
            component: () => import('@/views/spotify.view.vue'),
            beforeEnter: authGuard,
        },
        {
            path: '/:roleType/:roleId/agma-exports',
            name: 'agma-exports',
            component: () => import('@/views/agma-exports.view.vue'),
            beforeEnter: authGuard,
        },
        {
            path: '/:roleType/:roleId/agma-news',
            name: 'agma-news',
            component: () => import('@/views/agma-news.view.vue'),
            beforeEnter: authGuard,
        },
        // AUTH
        {
            path: '/:roleType/:roleId/verify-email',
            name: 'auth-verify-email',
            component: () => import('@/views/misc/auth-verify-email.view'),
            // beforeEnter: authGuard,
        },
        {
            path: '/:roleType/:roleId/verified',
            name: 'auth-verified',
            component: () => import('@/views/misc/auth-verified.view'),
            // beforeEnter: authGuard,
        },
        {
            path: '/:roleType/:roleId/forbidden',
            name: 'forbidden',
            component: () => import('@/views/misc/forbidden.view'),
        },
    ],
});


// localization based on routing
// router.beforeEach((to, from, next) => {
//     if (to.query.code || to.query.state) {
//         return next();
//     }

//     if (from.query.code || from.query.state) {
//         return next();
//     }
//     // for the moment, keep the current language
//     let targetLang = (to.params.lang) ? to.params.lang : from.params.lang;

//     // if there is no language set, or the language currently present is not supported...
//     if (!targetLang || SUPPORTED_LANGUAGES.indexOf(targetLang) === -1) {
//         // detect the user language
//         const userLang = (navigator.language || navigator.userLanguage || i18n.fallbackLocale).split('-')[0];

//         // use the user language if supported, else use the fallback / default locale
//         targetLang = (SUPPORTED_LANGUAGES.indexOf(userLang) !== -1) ? userLang : i18n.fallbackLocale;
//     }

//     // set the locale
//     if (i18n.locale !== targetLang) {
//         i18n.locale = targetLang;
//     }

//     // set the param, if there is an update
//     if (to.params.lang !== targetLang) {
//         to.params.lang = targetLang;
//         next(Object.assign({}, to));
//     } else {
//         next();
//     }
// });
export default router;
