// Footer bar at the bottom of the screen with links to certain pages

<i18n>
{
    "en": {
        "footer": {
            "imprint": "Imprint",
            "privacy": "Privacy",
            "terms": "Terms and Conditions"
        }
    },
    "de": {
        "footer": {
            "imprint": "Impressum",
            "privacy": "Datenschutz",
            "terms": "Aktuelle Teilnahmebedingungen"
        }
    }
}
</i18n>

<template>
    <div class="footer-bar">
        <div class="footer-bar__links">
            <a
                data-cy="footer-terms"
                class="footer-bar__link"
                onclick="window.open('https://podwatch-pro-public-files.s3.eu-central-1.amazonaws.com/Teilnahmebedingungen+ma+Podcast.pdf'), '_blank'"
            >
                {{ $t('footer.terms') }}
            </a>
            <a
                data-cy="footer-imprint"
                class="footer-bar__link"
                @click="goTo('imprint')"
            >
                {{ $t('footer.imprint') }}
            </a>
            <a
                data-cy="footer-privacy"
                class="footer-bar__link"
                @click="openWindow(externalRoutes.privacy)"
            >
                {{ $t('footer.privacy') }}
            </a>
        </div>
        <div class="footer-bar__version">
            v.{{ version.version }}
        </div>
    </div>
</template>

<script>
import GlobalFunctionsMixin from '@/mixins/globalFunctions.mixin.js';

import version from '@/assets/VERSION.json';

export default {
    name: 'FooterBar',
    mixins: [
        GlobalFunctionsMixin,
    ],
    data: function() {
        return {
            externalRoutes: {
                'privacy': 'https://www.agma-mmc.de/datenschutz/',
            },
            version: version,
        };
    },
    methods: {
        /** Opens the link in a new window
         * @param {String} target targeturl
         */
        openWindow(target) {
            window.open(target);
        },
    },
};
</script>

<style lang="scss" scoped>
.footer-bar {
    position: relative;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
    background-color: $tertiary-color;
    width: 100vw;
    height: 70px;
    left: 50%;
    transform: translateX(-50vw);

    &__links {
        grid-column: 2;
    }

    &__link,
    &__version {
        color: #4a4a4a;
        font-family: Roboto;
        font-size: 1.4rem;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: -0.12px;

        &:not(:last-child) {
            margin-right: 10px;
        }
    }

    &__version {
        grid-column: 3;
        display: flex;
        justify-content: flex-end;
        margin-right: 3rem;
    }
}
</style>
