import {createAPIClient} from './service.base';

const client = createAPIClient('/list-hosters/');

export const hostersService = {
    fetchHosters: async (filter = '') => {
        const hosters = await client.get(filter);
        return hosters.data;
    },
};
