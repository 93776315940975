export const createAuthModule = (authService, ROLES_APP_NAMEROLES_APP_NAME) => ({
    namespaced: true,
    state() {
        return {
            me: null,
            lastMeRefresh: null,
        };
    },
    mutations: {
        finishLoad: (state, tenants) => {
            state.tenants = tenants;
            state.tenantsLoaded = true;
        },
        startLoad: (state) => {
            state.tenantsLoaded = false;
        },
        failLoad: (state, error) => {
            state.tenantsLoaded = true;
            state.tenantsError = error;
        },
        setMe: (state, {me, now}) => {
            state.lastMeRefresh = now + 1000 * 60 * 2;
            state.me = me;
        },
    },
    actions: {
    },
    getters: {
        roles: (state) => state.me ? state.me.roles : [],
    },
});
